import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import {
    TRANSACTIONS_ROOT, TRANSACTIONS_ROOT_V2, TXN_ORDER_STATUS_LIST_ENDPOINT, TXN_PAYMENT_PROTOCOL_LIST_ENDPOINT,
    CREDIT_CARD_TRANSACTIONS_ENDPOINT, FIRST_OR_LAST_SET_CREDIT_CARD_TRANSACTIONS_ENDPOINT, CREDIT_CARD_NEXT_QUESTION_ENDPOINT,
    CREDIT_CARD_RAISE_DISPUTE_ENDPOINT, CREDIT_CARD_DISPUTE_INFO_ENDPOINT, DISPUTE_DETAILS_ENDPOINT,
} from '../../api/routes';

import {
    GET_TXNS_INFO, SET_TXNS_INFO, ERR_TXNS_INFO, GET_CHANNELS_INFO, SET_CHANNELS_INFO, ERR_CHANNELS_INFO,
    GET_QUESTIONS_INFO, SET_QUESTIONS_INFO, ERR_QUESTIONS_INFO, INITIATE_RAISE_DISPUTE, SUCCESS_RAISE_DISPUTE,
    ERR_RAISE_DISPUTE, GET_DISPUTE_INFO, SET_DISPUTE_INFO, ERR_DISPUTE_INFO, GET_FIRST_OR_LAST_SET_TXN_LIST,
    SET_FIRST_OR_LAST_SET_TXN_LIST, ERR_FIRST_OR_LAST_SET_TXN_LIST, GET_ORDER_STATUS_LIST, SET_ORDER_STATUS_LIST,
    GET_PAYMENT_PROTOCOL_LIST, SET_PAYMENT_PROTOCOL_LIST, GET_CREDIT_CARD_TXNS_INFO, GET_FIRST_OR_LAST_SET_CREDIT_CARD_TXN_LIST,
    GET_CREDIT_CARD_QUESTIONS_INFO, INITIATE_RAISE_DISPUTE_FOR_CREDIT_CARD_TXN,
} from './constants';
import { setAuthScreen } from '../Tickets/saga';

function* getTxnsInfo(action) {
    const { ticketId, index } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            TRANSACTIONS_ROOT_V2,
            action.data,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_TXNS_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_TXNS_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TXNS_INFO, data: { ticketId, err: e.message } });
    }
}

function* getCreditCardTxnsInfoSaga(action) {
    const { ticketId, index } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            CREDIT_CARD_TRANSACTIONS_ENDPOINT,
            action.data,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_TXNS_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_TXNS_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TXNS_INFO, data: { ticketId, err: e.message } });
    }
}

function* getFirstOrLastSetTxnList(action) {
    const {
        ticketId, userId, index, firstSet, lastSet,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, userId, firstSet, lastSet,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTIONS_ROOT}/first-or-last-set-txns?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({
                type: SET_FIRST_OR_LAST_SET_TXN_LIST,
                data: {
                    ticketId, ...response, firstSet, lastSet,
                },
            });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_FIRST_OR_LAST_SET_TXN_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FIRST_OR_LAST_SET_TXN_LIST, data: { ticketId, err: e.message } });
    }
}

function* getFirstOrLastSetCreditCardTxnListSaga(action) {
    const {
        ticketId, userId, index, firstSet, lastSet,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, userId, firstSet, lastSet,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FIRST_OR_LAST_SET_CREDIT_CARD_TRANSACTIONS_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({
                type: SET_FIRST_OR_LAST_SET_TXN_LIST,
                data: {
                    ticketId, ...response, firstSet, lastSet,
                },
            });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_FIRST_OR_LAST_SET_TXN_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FIRST_OR_LAST_SET_TXN_LIST, data: { ticketId, err: e.message } });
    }
}

export function* getChannelsInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTIONS_ROOT}/dispute/channels?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_CHANNELS_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_CHANNELS_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CHANNELS_INFO, data: { ticketId, err: e.message } });
    }
}

export function* getQuestionsInfo(action) {
    const {
        ticketId, userId, index, questionIndex, id, answer, reset, txnId, disputeType,
    } = action.data;

    let queryString = getQueryStringFromObject({
        ticketId, userId, id, txnId, disputeType,
    });
    queryString = `${queryString}&answer=${btoa(answer)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTIONS_ROOT}/dispute/questions?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({
                type: SET_QUESTIONS_INFO,
                data: {
                    ticketId, ...response, questionIndex, reset,
                },
            });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_QUESTIONS_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_QUESTIONS_INFO, data: { ticketId, err: e.message } });
    }
}

function* getCreditCardQuestionsInfoSaga(action) {
    const {
        ticketId, userId, index, questionIndex, questionCode, answer, reset,
    } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, userId });

    const payload = {
        questionCode,
        answer,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${CREDIT_CARD_NEXT_QUESTION_ENDPOINT}?${queryString}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({
                type: SET_QUESTIONS_INFO,
                data: {
                    ticketId, ...response, questionIndex, reset,
                },
            });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_QUESTIONS_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_QUESTIONS_INFO, data: { ticketId, err: e.message } });
    }
}

export function* raiseTxnDispute(action) {
    const {
        ticketId, userId, index, channel, channelOption, txnId, questionList,
        amount,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });
    const payload = {
        channel,
        channelOption,
        txnId,
        questionList,
        amount,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${TRANSACTIONS_ROOT}/dispute/raise?${queryString}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SUCCESS_RAISE_DISPUTE, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_RAISE_DISPUTE, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_RAISE_DISPUTE, data: { ticketId, err: e.message } });
    }
}

export function* raiseDisputeForCreditCardTxnSaga(action) {
    const {
        ticketId, userId, index, txnId, questionList,
        cardActionType, disputeType, amount,
    } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, userId });

    const payload = {
        txnId,
        questionList,
        cardActionType,
        disputeType,
        amount,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${CREDIT_CARD_RAISE_DISPUTE_ENDPOINT}?${queryString}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SUCCESS_RAISE_DISPUTE, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_RAISE_DISPUTE, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_RAISE_DISPUTE, data: { ticketId, err: e.message } });
    }
}

export function* getDisputeInfoSaga(action) {
    const {
        ticketId, userId, index, txnId, reject, creditCardDisputeInfo, fetchAllDisputes,
        key,
    } = action.data;

    const queryString = getQueryStringFromObject({
        ticketId, userId, txnId, fetchAllDisputes,
    });

    let apiEndPoint = DISPUTE_DETAILS_ENDPOINT;

    if (creditCardDisputeInfo) {
        apiEndPoint = CREDIT_CARD_DISPUTE_INFO_ENDPOINT;
    }

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${apiEndPoint}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            let hasDisputeInfo = true;

            if (!response.isTable && Object.keys(response.disputeInfo).length === 0) {
                hasDisputeInfo = false;

                if (reject) { // raise dispute - open the dispute flow
                    yield call(reject);
                } else { // view dispute details - show toast
                    toastify('No dispute raised!', 'success');
                }
            }

            yield put({
                type: SET_DISPUTE_INFO,
                data: {
                    ticketId, ...response, hasDisputeInfo, key,
                },
            });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_DISPUTE_INFO, data: { ticketId, key } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DISPUTE_INFO, data: { ticketId, err: e.message, key } });
    }
}

export function* getOrderStatusList() {
    const response = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        TXN_ORDER_STATUS_LIST_ENDPOINT,
    );

    yield put({ type: SET_ORDER_STATUS_LIST, data: response });
}

export function* getPaymentProtocolList() {
    const response = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        TXN_PAYMENT_PROTOCOL_LIST_ENDPOINT,
    );

    yield put({ type: SET_PAYMENT_PROTOCOL_LIST, data: response });
}

export default function* transactionsSaga() {
    yield all(
        [
            yield takeLatest(GET_TXNS_INFO, getTxnsInfo),
            yield takeLatest(GET_CREDIT_CARD_TXNS_INFO, getCreditCardTxnsInfoSaga),
            yield takeLatest(GET_FIRST_OR_LAST_SET_TXN_LIST, getFirstOrLastSetTxnList),
            yield takeLatest(GET_FIRST_OR_LAST_SET_CREDIT_CARD_TXN_LIST, getFirstOrLastSetCreditCardTxnListSaga),
            yield takeLatest(GET_CHANNELS_INFO, getChannelsInfo),
            yield takeLatest(GET_QUESTIONS_INFO, getQuestionsInfo),
            yield takeLatest(INITIATE_RAISE_DISPUTE, raiseTxnDispute),
            yield takeLatest(GET_DISPUTE_INFO, getDisputeInfoSaga),
            yield takeLatest(GET_ORDER_STATUS_LIST, getOrderStatusList),
            yield takeLatest(GET_PAYMENT_PROTOCOL_LIST, getPaymentProtocolList),
            yield takeLatest(GET_CREDIT_CARD_QUESTIONS_INFO, getCreditCardQuestionsInfoSaga),
            yield takeLatest(INITIATE_RAISE_DISPUTE_FOR_CREDIT_CARD_TXN, raiseDisputeForCreditCardTxnSaga),
        ],
    );
}
