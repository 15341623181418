/**
 * @file Left Arrow Component
 */
import React from 'react';

import { ICONS_URL } from '../../../../constants/assets';

import '../styles.scss';

const LeftArrow = () => (
    <div className='arrow__left'>
        <div className='arrow-icon'>
            <img src={ICONS_URL.LEFT_ARROW_WHITE} alt='left arrow' />
        </div>
    </div>
);

export default LeftArrow;
