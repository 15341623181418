import { createSelector } from 'reselect';

import { TRANSACTIONS_KEY } from './constants';

const selectTxns = (state) => state.get(TRANSACTIONS_KEY);
const selectFirstOrLastSetTxnList = (state) => state.getIn([TRANSACTIONS_KEY, 'firstOrLastSetTxnList']);

const makeSelectOrderStatusList = createSelector(
    (state) => state.getIn([TRANSACTIONS_KEY, 'orderStatusList']),
    (substate) => substate.toJS(),
);

const makeSelectPaymentProtocolList = createSelector(
    (state) => state.getIn([TRANSACTIONS_KEY, 'paymentProtocolList']),
    (substate) => substate.toJS(),
);

const makeSelectSearchParameters = createSelector(
    (state) => state.getIn([TRANSACTIONS_KEY, 'searchParameters']),
    (substate) => substate.toJS(),
);

const makeSelectTxnsList = createSelector(
    selectTxns,
    (substate) => getOrDefaultValue(substate, 'transactionsList'),
);

const makeSelectChannelList = createSelector(
    selectTxns,
    (substate) => getOrDefaultValue(substate, 'channelList'),
);

const makeSelectQuestionList = createSelector(
    selectTxns,
    (substate) => getOrDefaultValue(substate, 'questionList'),
);

const makeSelectDisputeInfo = createSelector(
    selectTxns,
    (substate) => getOrDefaultValue(substate, 'disputeInfo'),
);

const makeSelectTxnDisputeInfo = createSelector(
    selectTxns,
    (substate) => substate.get('selectedTxnDisputeInfo').toJS(),
);

const makeSelectFirstOrLastSetTxnList = createSelector(
    selectFirstOrLastSetTxnList,
    (substate) => substate.toJS(),
);

const makeSelectDisputeTableData = createSelector(
    selectTxns,
    (substate) => getOrDefaultValue(substate, 'disputeTableData'),
);

const getOrDefaultValue = (substate, key) => {
    if (substate) {
        const info = substate.get(key);
        if (info) {
            return info.toJS();
        }
    }
    return null;
};

export {
    makeSelectOrderStatusList,
    makeSelectPaymentProtocolList,
    makeSelectSearchParameters,
    makeSelectTxnsList,
    makeSelectChannelList,
    makeSelectQuestionList,
    makeSelectDisputeInfo,
    makeSelectTxnDisputeInfo,
    makeSelectFirstOrLastSetTxnList,
    makeSelectDisputeTableData,
};
