import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';

import { imageNodeProxyRoute } from '../../utils/downloadFile';
import { injectReducer, injectSaga } from '../../utils';
import { Button } from '../../components';

import { selectVKYCConfig } from '../VKYCFlow/selectors';
import ReportDetails from '../VKYCFlow/ReportDetails';
import Remarks from '../VKYCFlow/Remarks';

import { reviewReport, setScreen, getVkycAvailableReviewCount } from './actions';
import {
    selectRecordingUrl, selectUser, selectMeetingId, selectScreenKey,
    selectReport, selectRejectedReasons, selectVkycAvailableReviewCount,
} from './selectors';
import { AUDITOR_SCREEN_TYPE, FETCH_AUDIT_CASE } from './constants';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';

const VkycAuditorFlow = () => {
    const recordingUrl = useSelector(selectRecordingUrl, shallowEqual);
    const customerName = useSelector(selectUser, shallowEqual);
    const meetingId = useSelector(selectMeetingId, shallowEqual);
    const screenKey = useSelector(selectScreenKey, shallowEqual);
    const reportData = useSelector(selectReport, shallowEqual);
    const rejectedReasons = useSelector(selectRejectedReasons, shallowEqual) || [];
    const waitingReports = useSelector(selectVkycAvailableReviewCount);
    const vkycConsulConfig = useSelector(selectVKYCConfig);

    const dispatch = useDispatch();

    const [remarkState, setRemarkState] = React.useState({
        visible: false,
        comment: '',
        rejectionReason: '',
    });

    const timerRef = React.useRef(null);

    const pollingInterval = vkycConsulConfig?.pollingInterval || 3000;

    useEffect(() => {
        if (screenKey === AUDITOR_SCREEN_TYPE.FINAL_SCREEN) {
            setRemarkState((prev) => ({
                ...prev, visible: false, comment: '', rejectionReason: '',
            }));
        }
    }, [screenKey]);

    const reviewReportAction = (isApproved) => {
        dispatch(reviewReport({
            isApproved,
            meetingId,
            rejectionReason: remarkState?.rejectionReason,
            remarks: remarkState?.comment,
        }));
    };

    useEffect(() => {
        if (timerRef.current === null || timerRef.current === undefined) {
            dispatch(getVkycAvailableReviewCount());
            timerRef.current = setInterval(() => {
                if (!meetingId) dispatch(getVkycAvailableReviewCount());
            }, pollingInterval);
        }

        if (meetingId && timerRef.current) clearInterval(timerRef.current);

        return () => clearInterval(timerRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData]);

    useEffect(() => () => {
        clearInterval(timerRef.current);
    }, []);

    if (screenKey === AUDITOR_SCREEN_TYPE.INITIATE_SCREEN) {
        return (
            <div className='vkyc-auditor-review-success'>
                <div className='vkyc-auditor-review-text'>
                    <Button
                        v2
                        primary
                        type='button'
                        label='Initiate Review'
                        extClasses='vkyc-auditor-screen-btn-container-initiate'
                        onClick={() => {
                            dispatch({
                                type: FETCH_AUDIT_CASE,
                            });
                        }}
                        disabled={waitingReports === 0}
                    />
                </div>
            </div>
        );
    }

    if (screenKey === AUDITOR_SCREEN_TYPE.FINAL_SCREEN) {
        return (
            <div className='vkyc-auditor-review-success'>
                <div className='vkyc-auditor-review-text'>Review Success</div>
                <div className='vkyc-auditor-screen-btn-container'>
                    <Button
                        type='button'
                        label='Review New Case'
                        extClasses='vkyc-auditor-screen-btn-container-initiate'
                        onClick={() => {
                            dispatch(setScreen(AUDITOR_SCREEN_TYPE.INITIATE_SCREEN));
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className='vkyc-auditor-page-container'>
            <div className='vkyc-auditor-video-container'>
                <div className='vkyc-auditor-video-container-player'>
                    <ReactPlayer
                        playsinline // extremely crucial prop - needed by videosdk
                        pip={false}
                        light={false}
                        controls
                        muted
                        playing
                        //
                        url={imageNodeProxyRoute(recordingUrl)}
                        //
                        onError={(err) => {
                            // eslint-disable-next-line no-console
                            console.log(err, 'Error while streaming the pre-recorded video url');
                        }}
                        stopOnUnmount
                    />
                </div>
                <div className='vkyc-auditor-video-container-user-details'>
                    <div className='vkyc-auditor-video-container-user-details-container'>
                        <div className='vkyc-auditor-video-container-user-details-container-text'>
                            <div className='vkyc-auditor-video-container-user-details-container-text-label'>
                                Name
                            </div>
                            <div className='vkyc-auditor-video-container-user-details-container-text-value'>
                                {customerName}
                            </div>
                        </div>
                        <div className='vkyc-auditor-video-container-user-details-container-action'>
                            <Button
                                label='Reject'
                                extClasses='vkyc-auditor-video-container-user-details-container-action-btn reject'
                                onClick={() => {
                                    setRemarkState((prev) => ({
                                        ...prev, visible: true, rejectionReason: '', comment: '',
                                    }));
                                }}
                            />
                            <Button
                                label='Approve'
                                extClasses='vkyc-auditor-video-container-user-details-container-action-btn approve'
                                onClick={() => {
                                    reviewReportAction(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='vkyc-auditor-case-details'>
                <ReportDetails
                    reportData={reportData}
                />
            </div>
            <Remarks
                remarkState={remarkState}
                reviewReportAction={reviewReportAction}
                rejectedReasons={rejectedReasons}
                setRemarkState={setRemarkState}
            />
        </div>
    );
};

const withReducer = injectReducer({ key: 'vkycAuditor', reducer });
const withSaga = injectSaga({ key: 'vkycAuditor', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(VkycAuditorFlow));
