/**
 * @file Manages the saga generator functions for transaction queue container
 */

import {
    call, put, takeLatest, all, takeLeading,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, toastify, getErrMsg, getQueryStringFromObject, showErrorToastMsg, getErrorObjectForSaga,
} from '../../utils';
import {
    TRANSACTION_RISK_QUEUE_ENDPOINT,
    TRANSACTION_RISK_QUEUE_FILTER_ENDPOINT,
    TRANSACTION_RISK_PRIORITIZE_CASE_ENDPOINT,
} from '../../api/routes';

import {
    GET_TRANSACTION_QUEUE,
    SET_TRANSACTION_QUEUE,
    ERR_TRANSACTION_QUEUE,
    GET_TRANSACTION_QUEUE_FILTER,
    SET_TRANSACTION_QUEUE_FILTER,
    ERR_TRANSACTION_QUEUE_FILTER,
    GET_PRIORITIZED_CASE,
    SET_PRIORITIZED_CASE,
    ERR_PRIORITIZED_CASE,
    TransactionPageConfig,
} from './constants';

const getErrorAction = (actionType, containerKey) => ({
    type: actionType,
    data: { containerKey },
});

function* getTransactionQueueSaga(action) {
    const { filters = null, prevToken = null, nextToken = null } = action.data || {};

    const queryString = getQueryStringFromObject({
        filters, prevToken, nextToken,
    } || {});

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_QUEUE_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_TRANSACTION_QUEUE, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({
            type: ERR_TRANSACTION_QUEUE,
            data: { err: getErrMsg(e) },
        });
    }
}

function* getTransactionQueueFilterSaga(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_QUEUE_FILTER_ENDPOINT}`,
        );

        if (response) {
            response.listType = action?.data?.listType;
        }

        yield put({ type: SET_TRANSACTION_QUEUE_FILTER, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({
            type: ERR_TRANSACTION_QUEUE_FILTER,
            data: { err: getErrMsg(e) },
        });
    }
}

function* getPrioritizedCaseSaga(action) {
    try {
        const { goToCaseDetailPage, reviewType } = action.data;
        const queryString = getQueryStringFromObject({
            reviewTypes: TransactionPageConfig[reviewType]?.priorityFilters,
        });
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_PRIORITIZE_CASE_ENDPOINT}?${queryString}`,
        );

        goToCaseDetailPage(response?.case?.id);
        yield put({ type: SET_PRIORITIZED_CASE, data: response });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_PRIORITIZED_CASE), error));
    }
}

export default function* transactionQueueSaga() {
    yield all([
        yield takeLatest(GET_TRANSACTION_QUEUE, getTransactionQueueSaga),
        yield takeLatest(GET_TRANSACTION_QUEUE_FILTER, getTransactionQueueFilterSaga),
        yield takeLeading(GET_PRIORITIZED_CASE, getPrioritizedCaseSaga),
    ]);
}
