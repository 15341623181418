import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, LoaderOrError } from '../../../components';
import { selectVkycData, selectRejectedReasons, selectFileLocalStoreStatus, selectVKYCConfig } from '../selectors';
import {
    getConcludeCall, getEndVKYCCall, getGenerateVKYCReport, logStageProgress, muteAudioStatus,
    setLoaderState,
} from '../actions';
import ReportDetails from '../ReportDetails';
import Remarks from '../Remarks';
import { VKYC_STAGES } from '../constants';

const AGENT_REPORT_STAGE = 'AGENT_REPORT_STAGE';

const FinalScreen = ({ stopRecording }) => {
    const dispatch = useDispatch();

    const vkycData = useSelector(selectVkycData, shallowEqual);
    const rejectedReasons = useSelector(selectRejectedReasons, shallowEqual) || [];
    const localStoreStatus = useSelector(selectFileLocalStoreStatus);
    const vkycConsulConfig = useSelector(selectVKYCConfig);

    const [isConfirmed, setIsConfirmed] = useState();
    const [remarkState, setRemarkState] = useState({
        visible: false,
        comment: '',
        rejectionReason: '',
    });

    const { meetingId } = vkycData?.data || {};
    const { reports, loading: loadingMeeting, err } = vkycData?.meetings?.[meetingId] || {};
    const { report: reportData, loading: loadingReport } = reports || {};

    const loading = vkycData?.loading || loadingReport || loadingMeeting;

    useEffect(() => {
        dispatch(logStageProgress({
            message: 'VKYC Report Review started',
            stageName: VKYC_STAGES.VKYC_REPORT,
            subStageType: 'Report Review',
            meetingId,
        }));
        dispatch(getEndVKYCCall({
            meetingId,
            isAccepted: true,
            screenKey: ['meetings', meetingId, 'endCall'],
        }));

        const agentResponses = Object.entries(vkycData?.meetings[meetingId]?.answers || {}).map(([key, value]) => ({
            question: value.question,
            question_type: key,
            string_answer: value?.stringAnswer,
            image_answers: [{
                image_identifier: value?.cameraFront?.imageIdentifier || value?.cameraRear?.imageIdentifier,
            }],
            accepted: value?.accepted,
            remarks: value?.remarks,
        }));

        dispatch(muteAudioStatus(true));

        dispatch(getGenerateVKYCReport({
            questionAnswers: agentResponses,
            meetingId,
            screenKey: ['meetings', meetingId, 'reports'],
        }));
    }, []);

    const handleImageReload = (title) => ({ isLoading, isFinal, errText }) => {
        if (isLoading) {
            dispatch(logStageProgress({
                meetingId,
                message: `Image (${title}) fetch failed. Retrying...`,
                stageName: VKYC_STAGES.VKYC_REPORT,
                subStageType: 'Report Review',
            }));
            if (!loading) dispatch(setLoaderState('Image fetch failed. Retrying...'));
        } else {
            if (isFinal) {
                dispatch(logStageProgress({
                    meetingId,
                    message: `Image (${title}) fetch failed. ${errText}`,
                    stageName: VKYC_STAGES.VKYC_REPORT,
                    subStageType: 'Report Review',
                }));
            }
            dispatch(setLoaderState(false));
        }
    };

    const concludeCall = () => {
        const s3Url = vkycData?.data?.recordingUploadURL;
        dispatch(getConcludeCall({
            screenKey: ['meetings', meetingId],
            id: meetingId,
            Data: {
                overall_accepted: !!isConfirmed,
                rejection_reason: remarkState?.rejectionReason,
                remarks: remarkState?.comment,
            },
            meetingId,
            s3Url,
        }));
        setRemarkState((prev) => ({
            ...prev, visible: false,
        }));
    };

    const onApprove = (isCompleted = false) => () => {
        if (localStoreStatus) concludeCall();
        else {
            stopRecording();
            setIsConfirmed(!!isCompleted);
        }
    };

    const onReject = () => {
        if (localStoreStatus) concludeCall();
        else {
            stopRecording();
            setIsConfirmed(false);
        }
    };

    useEffect(() => {
        if (localStoreStatus) concludeCall();
    }, [localStoreStatus]);

    return (
        <div className='vkyc-final-scr-cr'>
            {reportData?.user_details
                && (
                    <div className='vkyc-final-scr-report'>
                        <ReportDetails
                            reportData={reportData}
                            handleImageReload={handleImageReload}
                        />
                    </div>
                )}

            {/* <div>Confirm if the customer’s Video KYC is successful?</div> */}
            <div className='vkyc-button-container'>
                <Button
                    v2
                    secondary
                    extClasses='vkyc-button-end'
                    label='Reject'
                    onClick={() => {
                        /**
                         * disable remark screen based on defaultDisabledRemarks flag
                         */
                        if (Array.isArray(vkycConsulConfig?.defaultDisabledRemarks)
                        && vkycConsulConfig?.defaultDisabledRemarks.includes(AGENT_REPORT_STAGE)) {
                            onReject();
                        } else {
                            setRemarkState((prev) => ({
                                ...prev, visible: true, rejectionReason: '', comment: '',
                            }));
                        }
                    }}
                />
                <Button
                    v2
                    primary
                    label='Approve'
                    onClick={onApprove(true)}
                />
            </div>
            <Remarks
                remarkState={remarkState}
                reviewReportAction={onReject}
                rejectedReasons={rejectedReasons}
                setRemarkState={setRemarkState}
            />
            <LoaderOrError loading={!!loading} errorLabel={err} loadingText={typeof loading === 'string' ? loading : ''} />
        </div>
    );
};

export default FinalScreen;
