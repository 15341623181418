/**
 * @file Common transaction risk utilities
 */

// eslint-disable-next-line import/no-cycle
import { ACCESS_LEVELS } from '../App/constants';
import {
    RISK_OPS_TXN_PATH,
    RISK_OPS_TXN_CASE_DETAILS_PATH,
    RISK_OPS_USER_CASE_DETAILS_PATH,
    RISK_OPS_TXN_VIEW_PATH,
    RISK_OPS_USER_VIEW_PATH,
    RISK_OPS_TXN_USER_VIEW_PATH,
    RISK_OPS_USER_PATH,
    RISK_OPS_USER_CASE_SUBMIT_ACTIONS_PATH,
    RISK_OPS_TXN_ANNOTATION_PATH,
    RISK_CASE_USER_PATH,
    RISK_CASE_TXN_PATH,
    RISK_OPS_CASES_CASE_DETAILS_PATH,
    RISK_OPS_USER_AFU_HISTORY_PATH,
    RISK_OPS_USER_PRODUCTS_PATH,
    RISK_OPS_CASES_TXN_VIEW_PATH,
    RISK_OPS_CASES_USER_VIEW_PATH,
    RISK_OPS_CASES_USER_RESPONSE,
    RISK_OPS_TXN_USER_RESPONSE,
    RISK_OPS_USER_USER_RESPONSE,
    RISK_OPS_CASES_RISK_ACTIVITY,
    RISK_OPS_TXN_RISK_ACTIVITY,
    RISK_OPS_USER_RISK_ACTIVITY,
    ESCALATION_REVIEW_PATH,
    MULTI_QUEUE_REVIEW_PATH,
} from '../App/routes';

import {
    TXN_LIST_VIEW, TXN_DETAIL_VIEW, USER_DETAIL_VIEW, USER_LIST_VIEW, CASE_DETAIL_VIEW,
} from './constants';

export const getMenuStructure = (metaInfo, type = TXN_LIST_VIEW, accessLevel = null) => {
    const menu = [];

    const {
        riskOps: {
            userProducts,
        },
    } = metaInfo;

    switch (type) {
        case TXN_LIST_VIEW: {
            menu.push(
                {
                    id: 'transaction-queue',
                    label: 'Transaction Queue',
                    path: RISK_OPS_TXN_PATH,
                },
            );

            break;
        }

        case TXN_DETAIL_VIEW: {
            menu.push(
                {
                    id: 'case-details',
                    label: 'Case Details',
                    path: RISK_OPS_TXN_CASE_DETAILS_PATH,
                },
                {
                    id: 'transaction-view',
                    label: 'Transaction View',
                    path: RISK_OPS_TXN_VIEW_PATH,
                    // Remove Transaction View menu for userOutcall and kycAnalyst Roles
                    hidden: accessLevel === ACCESS_LEVELS.userOutcall || accessLevel === ACCESS_LEVELS.kycAnalyst,
                },
                {
                    id: 'transaction-user-view',
                    label: 'User View',
                    path: RISK_OPS_TXN_USER_VIEW_PATH,
                },
                {
                    id: 'submit-final-action',
                    label: 'Submit Action',
                    path: RISK_OPS_TXN_ANNOTATION_PATH,
                },
                {
                    id: 'user-response',
                    label: 'User Response',
                    path: RISK_OPS_TXN_USER_RESPONSE,
                    hidden: ![ACCESS_LEVELS.riskOpsAdmin, ACCESS_LEVELS.riskOps].includes(accessLevel),
                },
                {
                    id: 'recent-activity',
                    label: 'Recent Activity',
                    path: RISK_OPS_TXN_RISK_ACTIVITY,
                    hidden: ![ACCESS_LEVELS.riskOpsAdmin, ACCESS_LEVELS.riskOps].includes(accessLevel),
                },
            );

            break;
        }

        case USER_LIST_VIEW: {
            menu.push(
                {
                    id: 'kyc-queue',
                    label: 'KYC Queue',
                    path: RISK_OPS_USER_PATH,
                },
            );

            break;
        }

        case USER_DETAIL_VIEW: {
            menu.push(
                {
                    id: 'case-details',
                    label: 'Case Details',
                    path: RISK_OPS_USER_CASE_DETAILS_PATH,
                },
                {
                    id: 'transaction-user-view',
                    label: 'User View',
                    path: RISK_OPS_USER_VIEW_PATH,
                },
                {
                    id: 'afu-history',
                    label: 'AFU History',
                    path: RISK_OPS_USER_AFU_HISTORY_PATH,
                },
                {
                    id: 'user-products',
                    label: 'Products',
                    path: RISK_OPS_USER_PRODUCTS_PATH,
                    hidden: !userProducts, // feature flag
                },
                {
                    id: 'submit-final-action',
                    label: 'Submit Action',
                    path: RISK_OPS_USER_CASE_SUBMIT_ACTIONS_PATH,
                },
                {
                    id: 'user-response',
                    label: 'User Response',
                    path: RISK_OPS_USER_USER_RESPONSE,
                    hidden: ![ACCESS_LEVELS.riskOpsAdmin, ACCESS_LEVELS.riskOps].includes(accessLevel),
                },
                {
                    id: 'recent-activity',
                    label: 'Recent Activity',
                    path: RISK_OPS_USER_RISK_ACTIVITY,
                    hidden: ![ACCESS_LEVELS.riskOpsAdmin, ACCESS_LEVELS.riskOps].includes(accessLevel),
                },
            );

            break;
        }
        case CASE_DETAIL_VIEW: {
            menu.push(
                {
                    id: 'case-details',
                    label: 'Case Details',
                    path: RISK_OPS_CASES_CASE_DETAILS_PATH,
                },
                {
                    id: 'transaction-view',
                    label: 'Transaction View',
                    path: RISK_OPS_CASES_TXN_VIEW_PATH,
                    hidden: accessLevel !== ACCESS_LEVELS.riskOpsAdmin,
                },
                {
                    id: 'transaction-user-view',
                    label: 'User View',
                    path: RISK_OPS_CASES_USER_VIEW_PATH,
                    hidden: accessLevel !== ACCESS_LEVELS.riskOpsAdmin,
                },
                {
                    id: 'user-response',
                    label: 'User Response',
                    path: RISK_OPS_CASES_USER_RESPONSE,
                    hidden: ![ACCESS_LEVELS.riskOpsAdmin, ACCESS_LEVELS.riskOps].includes(accessLevel),
                },
                {
                    id: 'recent-activity',
                    label: 'Recent Activity',
                    path: RISK_OPS_CASES_RISK_ACTIVITY,
                    hidden: ![ACCESS_LEVELS.riskOpsAdmin, ACCESS_LEVELS.riskOps].includes(accessLevel),
                },
            );

            break;
        }

        default: {
            return menu;
        }
    }

    return menu;
};

export const getPreFilledValuesObj = (data) => {
    const {
        caseId, emailId,
    } = data;

    return {
        mergeKey: 'name',
        paramList: [
            { name: 'case_id', value: caseId, isDisabled: true },
            { name: 'analyst_email', value: emailId, isDisabled: true },
        ],
    };
};

export const getCaseInitialRoute = ({ locationPath }) => {
    let ROUTE_PATH = TXN_LIST_VIEW;

    if (locationPath?.includes(RISK_OPS_TXN_PATH)) {
        ROUTE_PATH = RISK_CASE_TXN_PATH;
    } else if (locationPath?.includes(RISK_OPS_USER_PATH)) {
        ROUTE_PATH = RISK_CASE_USER_PATH;
    } else if (locationPath?.includes(ESCALATION_REVIEW_PATH)) {
        ROUTE_PATH = ESCALATION_REVIEW_PATH;
    } else if (locationPath?.includes(MULTI_QUEUE_REVIEW_PATH)) {
        ROUTE_PATH = MULTI_QUEUE_REVIEW_PATH;
    }

    return ROUTE_PATH;
};
