/**
 * @fileTransaction Transaction Risk Submit Action Page
 */

import React, { useMemo, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { RISK_OPS_FORM_ACTION_ROOT } from '../../../api/routes';

import DevActions from '../../DevActions';
import { getPrioritizedCaseAction } from '../../TransactionQueue/actions';

import PrioritizedCaseModal from '../modals/PrioritizedCaseModal';
// eslint-disable-next-line import/no-cycle
import { getCaseInitialRoute } from '../utils';

const RiskSubmitAction = (props) => {
    const {
        caseId, caseInfo = {}, updateCaseId,
    } = props;
    const { routePath, listType } = caseInfo;

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [showPrioritizedCaseModal, setShowPrioritizedCaseModal] = useState(false);

    const actionConfig = useMemo(() => ({
        payload: { caseId },
        CUSTOM_ACTION_ROOT: RISK_OPS_FORM_ACTION_ROOT,
    }), [caseId]);

    const handleCancel = () => {
        const locationPath = location?.pathname;
        setShowPrioritizedCaseModal(false);
        history.push(getCaseInitialRoute({ locationPath }));
    };

    const handleProceed = () => {
        dispatch(getPrioritizedCaseAction({
            goToCaseDetailPage: (newCaseId) => {
                updateCaseId(newCaseId);
                history.push(routePath, { caseId: newCaseId, caseInfo });
            },
            reviewType: listType,
            reviewTypes: [listType],
        }));
    };

    /**
     * Action fields Validator
     * @param {Array.<Object>} actionList
     * @returns {{status: boolean, message: string}}
     */
    const validateActions = (actionList) => {
        const minLenErrFields = [];
        const minLength = 4;
        actionList.forEach((item) => {
            if (item.type === 13 || item.type === 1) { // check for text and texaarea field
                const val = item.value?.trim();
                if (val && val.length < minLength) {
                    minLenErrFields.push(item.label);
                }
            }
        });

        const len = minLenErrFields.length;

        if (len) {
            return {
                status: false,
                message: `${minLenErrFields.join(', ')} field${len > 1 ? 's' : ''} must be at least ${minLength} characters long`,
            };
        }

        return { status: true, message: '' };
    };

    const onSuccessSubmit = () => {
        const locationPath = location?.pathname;

        if (routePath) {
            return setShowPrioritizedCaseModal(true);
        }

        // After 1 seconds will move to list page
        return setTimeout(() => {
            history.push(getCaseInitialRoute({ locationPath }));
        }, 1000);
    };

    return (
        <React.Fragment>
            <DevActions
                containerKey={`risk-submit-action-${caseId}`}
                actionConfig={actionConfig}
                validateActions={validateActions}
                onSuccessSubmit={onSuccessSubmit}
                autoActionSelection={false}
            />

            <PrioritizedCaseModal visible={showPrioritizedCaseModal} handleCancel={handleCancel} handleProceed={handleProceed} />
        </React.Fragment>
    );
};

export default memo(RiskSubmitAction);
