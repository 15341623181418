// The above comment must be removed while pushing the code to production

import React, {
    memo, useContext, useEffect, useRef, useState,
} from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { path } from 'ramda';

import { ScreenRecorder, StepsTraversalModal } from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';
import { UserDetailsContext } from '../../context';

import { storeFile } from './utils/fileStore';
import {
    setVKYCFlowStep, setRequiredPermissionStatus, setCurrentStagePointers, setLocalFileStoredStatus,
} from './actions';
import {
    selectVkycData, selectFlowProgress, selectAudioMuteFlag, selectCurrentStage, selectCurrentSubStage,
} from './selectors';
import {
    EVENT_LOG_INTERVAL, FLOW_PROGRESS, stagePointerPayload,
} from './constants';
import CallQueueScreen from './CallQueueScreen';
import FinalScreen from './FinalScreen';
import CallScreen from './CallScreen';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';

const VKYCFlow = () => {
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);
    const dispatch = useDispatch();

    const [stopFlag, setStopFlag] = useState(false);

    const audioMuteFlag = useSelector(selectAudioMuteFlag);
    const flowProgress = useSelector(selectFlowProgress);
    const vkycData = useSelector(selectVkycData, shallowEqual);
    const currentStep = useSelector(selectCurrentStage(vkycData?.data?.meetingId));
    const currentSubStep = useSelector(selectCurrentSubStage(vkycData?.data?.meetingId));

    const refreshCount = useRef(0);
    const screenRecorderRef = useRef();

    const stages = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages;

    const uploadToS3URL = (blob) => {
        const meetingId = vkycData?.data?.meetingId;
        if (!meetingId) return;
        const b1 = blob || new Blob([]);
        const fileName = `${Date.now()}-${vkycData?.data?.meetingId}-vkyc.mp4`;

        const file = new File([b1], fileName, { type: 'video/mp4' });
        storeFile(meetingId, file);
        dispatch(setLocalFileStoredStatus(true));
        setStopFlag(false);
    };

    const initiateRecordingSetup = () => {
        screenRecorderRef.current.startRecording();
    };

    const stopRecording = () => {
        setStopFlag(true);
    };

    useEffect(() => {
        if (refreshCount.current === 0 && (flowProgress === FLOW_PROGRESS.END_CALL_FAILED || flowProgress === FLOW_PROGRESS.END_CALL_SUCCESS)) {
            refreshCount.current = 1;
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [flowProgress]);

    const getRenderStep = () => {
        switch (flowProgress) {
            case FLOW_PROGRESS.INITIATE_CALL: // Finding customers for VKYC
                return (
                    <CallQueueScreen
                        initiateRecordingSetup={initiateRecordingSetup}
                        stopRecording={stopRecording}
                    />
                );
            case FLOW_PROGRESS.IN_PROGRESS: // Customer successfully joined the call
                return (
                    <CallScreen
                        stopRecording={stopRecording}
                    />
                );
            case FLOW_PROGRESS.END_CALL_FAILED:
            case FLOW_PROGRESS.END_CALL_SUCCESS: {
                return (
                    <div className='vkyc-final-banner-screen'>
                        <div className='vkyc-final-banner-title'>loading...</div>
                    </div>
                );
            }

            default: // If no step defined
                return <div>Setting up the session</div>;
        }
    };

    const restartOnClick = () => {
        dispatch(setVKYCFlowStep({ step: 0, meetingId: vkycData?.data?.meetingId }));
    };

    const backOnClick = () => {
        const totalStages = path(['meetings', vkycData?.data?.meetingId, 'stages', 'length'], vkycData);
        if (currentStep >= 0 || currentStep < totalStages) {
            const meetingId = vkycData?.data?.meetingId;
            if (currentSubStep === 0) {
                if (currentStep > 0) dispatch(setCurrentStagePointers(stagePointerPayload(meetingId, currentStep - 1, currentSubStep)));
            } else {
                const currentSubStages = path(['meetings', meetingId, 'stages', currentStep, 'stages'], vkycData);
                if (Array.isArray(currentSubStages) && currentSubStages.length > 0 && currentSubStep < currentSubStages.length) {
                    dispatch(setCurrentStagePointers(stagePointerPayload(meetingId, currentStep, currentSubStep - 1)));
                }
            }
        }
    };

    const setRequiredPermissions = (status, error) => {
        dispatch(setRequiredPermissionStatus({
            status,
            error,
        }));
    };

    const handleOnRecord = (isStarting, eventData) => {
        if (isStarting) {
            pushRudderEvent(RudderEvent.VKYC.VKYCAgentViewRecordingStarted, agentInfo.emailId, eventData);
        } else {
            pushRudderEvent(RudderEvent.VKYC.VKYCAgentViewRecordingOngoing, agentInfo.emailId, eventData);
        }
    };

    const handleOnStopRecord = (eventData) => {
        pushRudderEvent(RudderEvent.VKYC.VKYCAgentViewRecordingStopped, agentInfo.emailId, eventData);
    };

    useEffect(() => {
        // resetting permission state
        setRequiredPermissions(false, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const beforeUnload = (e) => {
            if (flowProgress === FLOW_PROGRESS.IN_PROGRESS) {
                e.preventDefault();
            }
            return '';
        };
        window.addEventListener('beforeunload', beforeUnload);
        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [flowProgress]);

    return (
        <div className='p-30 vkyc'>
            {
                ((!currentStep || !stages) || (currentStep < stages?.length)) ? (
                    <StepsTraversalModal
                        title={vkycData?.title}
                        buttonOnclick={restartOnClick}
                        backOnClick={backOnClick}
                        extClasses={{
                            container: 'vkyc-step',
                        }}
                    >
                        <div className='p-20'>
                            {getRenderStep()}
                        </div>
                    </StepsTraversalModal>
                ) : (
                    <FinalScreen stopRecording={stopRecording} />
                )
            }
            <ScreenRecorder
                ref={screenRecorderRef}
                upload={uploadToS3URL}
                stopFlag={stopFlag}
                setPermissionStatus={setRequiredPermissions}
                setStopFlag={setStopFlag}
                participantAudio={vkycData?.participantAudioStream}
                audioMuteFlag={audioMuteFlag}
                permissionCheck={setRequiredPermissions}
                onRecord={handleOnRecord}
                onStop={handleOnStopRecord}
                extraEventData={{
                    meetingId: vkycData?.data?.meetingId,
                }}
                eventLogInterval={EVENT_LOG_INTERVAL}
            />
        </div>
    );
};

const withReducer = injectReducer({ key: 'vkyc', reducer });
const withSaga = injectSaga({ key: 'vkyc', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(VKYCFlow));
