import React, { useState, useEffect, memo } from 'react';
import { Button, Modal } from '../../../components';

const DocumentContainerModal = (props) => {
    const { visible, handleCancel, contentSrc } = props;
    const [isPDF, setIsPDF] = useState(false);

    useEffect(() => {
        if (!contentSrc) return;
        const urlObject = new URL(contentSrc);
        const pathname = urlObject.pathname && urlObject.pathname.toLowerCase();
        if (pathname && pathname.endsWith('.pdf')) {
            setIsPDF(true);
        }
    }, [contentSrc]);

    if (!visible) return null;
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 2,
        },
        content: {
            top: '15%',
            left: '45%',
            right: 'auto',
            bottom: 'auto',
            padding: '32px 20px',
            transform: 'translateX(-50%) translateX(120px)',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
            width: '800px',
            border: '0',
            borderRadius: '12px',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        },
    };
    return (
        <Modal
            visible={visible}
            contentLabel='Document Verification'
            style={customModalStyles}
        >
            <div className='vkyc-modal__content'>
                {isPDF ? (
                    <iframe
                        src={contentSrc}
                        title='PDF Content'
                        className='vkyc-modal__content-iframe'
                        type='application/pdf'
                        frameBorder='0'
                    />
                ) : (
                    <img
                        src={contentSrc}
                        alt='Document'
                        className='vkyc-modal__content-image'
                    />
                )}
            </div>
            <div className='vkyc-modal__footer'>
                <Button
                    v2
                    primary
                    label='Cancel'
                    onClick={handleCancel}
                />
            </div>
        </Modal>
    );
};

export default memo(DocumentContainerModal);
