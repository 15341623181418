import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationStep from './LocationStep';
import FaceCaptureStep from './FaceCaptureStep';
import PANCardStep from './PANCardStep';
import QuestionStep from './QuestionStep';
import PassportCaptureStep from './PassportCaptureStep';
import { VKYC_STAGES } from '../constants';
import { selectVkycData } from '../selectors';
import { logStageProgress, setTitle } from '../actions';
import EmiratesIdCaptureStep from './EmiratesIdCaptureStep';
import DocVerificationStep from './DocVerificationStep';

const CurrentStage = ({ stopRecording, setNextStepEnable }) => {
    const dispatch = useDispatch();

    const vkycData = useSelector(selectVkycData);

    const meetingId = vkycData?.data?.meetingId;
    const {
        stepNumber: currentStep, subStepNumber: currentSubStep, stages, answers,
    } = vkycData?.meetings?.[meetingId] || {};

    useEffect(() => {
        const { stage_name: stageName, title, stages: subStages } = stages?.[currentStep] || {};
        if (title) dispatch(setTitle({ title }));

        if (stageName && subStages?.[currentSubStep]?.stage_type) {
            dispatch(logStageProgress({
                message: 'Stage started', stageName, subStageType: subStages?.[currentSubStep]?.stage_type, meetingId,
            }));
        }
    }, [currentStep, currentSubStep]);

    const handleSetNextStepEnable = (enable) => {
        setNextStepEnable(enable);
    };

    switch (stages?.[currentStep]?.stage_name) {
        case VKYC_STAGES.LIVENESS:
            return (
                <QuestionStep
                    data={stages[currentStep]}
                    answers={answers}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.LOCATION:
            return (
                <LocationStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.FACE_CAPTURE:
            return (
                <FaceCaptureStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.PAN_CAPTURE:
            return (
                <PANCardStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.PASSPORT_CAPTURE:
            return (
                <PassportCaptureStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    stopRecording={stopRecording}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.EMIRATES_ID_CAPTURE:
            return (
                <EmiratesIdCaptureStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    stopRecording={stopRecording}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.DOCUMENT_VERIFICATION:
            return (
                <DocVerificationStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    stopRecording={stopRecording}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        default:
            return <div>Setting up vkyc...</div>;
    }
};

export default CurrentStage;
