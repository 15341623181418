/**
*
* Button
*
*/

import React, { memo } from 'react';
import classNames from 'classnames';

import './style.scss';

const Button = (props) => {
    /**
     * v2 - to render buttons as per new design
     * link - to render button as link cta
     */
    const {
        disabled, onClick, label, primary, secondary, warning, link, extClasses, v2, tertiary,
    } = props;

    const btnClasses = classNames({
        'btn-wrapper': true,
        'btn-wrapper--primary': !v2 && primary,
        'btn-wrapper--primary-v2': v2 && primary,
        'btn-wrapper--secondary': !v2 && secondary,
        'btn-wrapper--secondary-v2': v2 && secondary,
        'btn-wrapper--tertiary': tertiary,
        'btn-wrapper--warning': warning,
        'btn-wrapper--link': link,
    }, extClasses);

    return (
        <button
            onClick={onClick}
            className={btnClasses}
            disabled={disabled}
            type='button'
        >
            {label}
        </button>
    );
};

export default memo(Button);
