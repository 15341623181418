/**
 * @file Constants for transction table
 */

export const TRANSACTION_REVIEW_V2_FILTERS = 'TRANSACTION_REVIEW_V2_FILTERS';
export const TRANSACTION_REVIEW_V2_ORDER = 'TRANSACTION_REVIEW_V2_ORDER';
export const TRANSACTION_REVIEW_V2_PINS = 'TRANSACTION_REVIEW_V2_PINS';
export const TRANSACTION_REVIEW_V2_COLUMN_WIDTH = 'TRANSACTION_REVIEW_V2_COLUMN_WIDTH';

export const ALL = 'ALL';
export const FILTERS = 'FILTERS';
export const ORDERS = 'ORDERS';
export const PINS = 'PINS';
export const COLWIDTH = 'COLWIDTH';
export const SELECTEDROWS = 'SELECTEDROWS';
export const PERIOD = 'PERIOD';

export const clearDropdownOptions = [
    {
        label: 'All',
        value: ALL,
    },
    {
        label: 'Column Orders',
        value: ORDERS,
    },
    {
        label: 'Column Width',
        value: COLWIDTH,
    },
    {
        label: 'Filters',
        value: FILTERS,
    },
    {
        label: 'Pinned Columns',
        value: PINS,
    },
    {
        label: 'Selected Rows',
        value: SELECTEDROWS,
    },
];

export const defaultPinnedColumns = ['credited_debited_at'];

export const COLUMN_WIDTH = 150;
export const COLUMN_MIN_WIDTH = 70;

export const TXN_DISPLAY_CATEGORY = {
    SELF_TRANSFER_DEBIT: 'SELF_TRANSFER_DEBIT',
    SELF_TRANSFER_CREDIT: 'SELF_TRANSFER_CREDIT',
};
