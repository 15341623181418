/**
 *
 * @file DocVerificationStep - Component to render the document verification step in VKYC flow in which document fetched from the CKYC server is verified
 *
 */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ImageUtil, { AutoScaleImageViewer } from '../../../../utils/imageUtils';
import { Button, Loader, ProgressBar } from '../../../../components';

import { matchScoreFormatting } from '../../utils/serverCodeFormatter';
import { selectVkycData, selectCurrentSubStage, selectCurrentStage } from '../../selectors';
import {
    getMatchScore, logStageProgress, setCurrentStagePointers,
    setFacematchImageStatus,
    setMeetingLoaderState,
} from '../../actions';
import {
    CAMERA_ORIENTATION, CAROUSEL_SCROLL_VALUE, ONBOARIDNG_STAGE_TYPE, stagePointerPayload,
} from '../../constants';

const DocVerificationStep = (props) => {
    const { data, meetingId, setAllAnsweredYes } = props;

    const { stages: subStages, matchScore } = data;

    const [isFaceMatchAnswerCorrect, setIsFaceMatchAnswer] = useState();
    const [imageOrientations, setImageOrientations] = useState({});
    const dispatch = useDispatch();

    const vkycData = useSelector(selectVkycData, shallowEqual);
    const subStageStep = useSelector(selectCurrentSubStage(meetingId));
    const currentStepNumber = useSelector(selectCurrentStage(meetingId));

    const { stages } = vkycData?.meetings?.[meetingId] || {};

    const setSubStageStep = (stepNumber) => {
        dispatch(logStageProgress({
            meetingId,
            message: stepNumber < subStageStep ? 'Stage reset' : 'Stage completed',
            stageName: stages?.[currentStepNumber]?.stage_name,
            subStageType: stages?.[currentStepNumber]?.stages?.[subStageStep]?.stage_type,
        }));
        dispatch(setCurrentStagePointers(stagePointerPayload(meetingId, currentStepNumber, stepNumber)));
    };

    const handleFaceMatchSubmit = (answer) => {
        setIsFaceMatchAnswer(answer);
        dispatch(setFacematchImageStatus({ meetingId, stepNumber: currentStepNumber, data: answer }));
    };

    const handleImageReload = ({ isLoading, isFinal, errText }) => {
        if (isLoading) {
            dispatch(logStageProgress({
                meetingId,
                message: 'Image fetch failed. Retrying...',
                stageName: stages?.[currentStepNumber]?.stage_name,
                subStageType: stages?.[currentStepNumber]?.stages?.[subStageStep]?.stage_type,
            }));
            dispatch(setMeetingLoaderState({ meetingId, loading: 'Image fetch failed. Retrying...' }));
        } else {
            if (isFinal) {
                dispatch(logStageProgress({
                    meetingId,
                    message: `Image fetch failed. ${typeof errText === 'string' ? errText : ''}`,
                    stageName: stages?.[currentStepNumber]?.stage_name,
                    subStageType: stages?.[currentStepNumber]?.stages?.[subStageStep]?.stage_type,
                }));
            }
            dispatch(setMeetingLoaderState({ meetingId, loading: false }));
        }
    };

    const getOrientationClass = (url) => {
        if (!url) return false;

        const img = new Image();
        img.src = url;
        img.onload = () => {
            const isHorizontal = img.width > img.height;
            setImageOrientations((prev) => ({ ...prev, [url]: isHorizontal }));
        };

        return imageOrientations[url];
    };

    const getMatchScoreData = ({ faceImage, documentImage, documentType }) => {
        dispatch(getMatchScore({
            livenessImageIdentifier: faceImage?.imageIdentifier,
            documentImageIdentifier: documentImage?.image_identifier,
            documentDetails: {
                document_type: documentType,
            },
            meetingId,
            screenKey: [
                'meetings',
                meetingId,
                'stages',
                vkycData?.meetings?.[meetingId]?.stepNumber,
                'matchScore',
            ],
        }));
    };

    const currentStepGlobal = data?.stages?.[subStageStep];
    const stepNumberGlobal = vkycData?.meetings?.[meetingId]?.stepNumber;
    const currentStageGlobal = vkycData?.meetings?.[meetingId]?.stages?.[stepNumberGlobal];
    const imgUrlGlobal = currentStageGlobal?.[currentStepGlobal?.image_capture?.camera_type]?.imageUrl;
    const documentImageGlobal = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages?.[stepNumberGlobal]?.[CAMERA_ORIENTATION.back];

    useEffect(() => {
        getOrientationClass(imgUrlGlobal);
        getOrientationClass(documentImageGlobal?.imageUrl);
    }, [imgUrlGlobal, documentImageGlobal?.imageUrl]);

    const getNextComponent = () => {
        const subStageData = subStages[subStageStep] || {};
        const { stage_data: stageData, stage_type: stageType } = subStageData || {};

        const faceImage = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages?.[2]?.[CAMERA_ORIENTATION.front];

        switch (stageType) {
            case ONBOARIDNG_STAGE_TYPE.FACE_MATCH: {
                const matchScorePercentage = data?.matchScore?.face_image_match_result?.match_score_percent;
                const idImage = subStages[0]?.doc_details_confirmation?.document_image?.presigned_read_url;
                const { instruction, description, document_image: documentImage } = subStageData[stageData];

                getMatchScoreData({ faceImage, documentImage });

                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{instruction}</div>
                        <div className='vkyc-image-container'>
                            <AutoScaleImageViewer
                                url={documentImage?.presigned_read_url || idImage}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                                pdfContainerClass='vkyc-pdf-container'
                                handleReload={handleImageReload}
                            />
                            <div className='vkyc-image-placeholder'>
                                <ImageUtil url={faceImage?.imageUrl} containerClass='vkyc-image' handleImageReload={handleImageReload} />
                            </div>
                            <div className='ml-30'>
                                <div className='mb-30'>
                                    <div className='sop-step-des'>Match Score</div>
                                    {matchScore?.loading ? <Loader visible />
                                        : (
                                            <div className='vkyc-match-score-container'>
                                                <div className={`vkyc-match-score-percentage
                                            ${matchScorePercentage >= 50
                                                ? 'vkyc-match-score-percentage--green' : 'vkyc-match-score-percentage--red'}
                                            }`}
                                                >
                                                    {matchScoreFormatting(matchScorePercentage)}%
                                                </div>
                                                <div className='vkyc-match-score-progress'>
                                                    <ProgressBar
                                                        number={matchScorePercentage || 0}
                                                        progressBarStyleClassName={
                                                            matchScorePercentage >= 50
                                                                ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                </div>
                                <div className='sop-step-des'>{description}</div>
                                <div className='frWrapper'>
                                    <Button
                                        v2
                                        label='Yes'
                                        onClick={() => {
                                            setAllAnsweredYes(true);
                                            handleFaceMatchSubmit(true);
                                        }}
                                        extClasses={{
                                            'vkyc-step-option': true,
                                            'vkyc-step-option-inactive': true,
                                            'vkyc-step-option--yes': isFaceMatchAnswerCorrect === true,
                                        }}
                                    />
                                    <Button
                                        v2
                                        label='Retake'
                                        onClick={() => {
                                            handleFaceMatchSubmit(false);
                                            setSubStageStep(subStageStep - 1);
                                        }}
                                        extClasses={{
                                            'vkyc-step-option': true,
                                            'vkyc-step-option-inactive': true,
                                            'vkyc-step-option--no': isFaceMatchAnswerCorrect === false,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            case ONBOARIDNG_STAGE_TYPE.DOCUMENT_DETAILS_CONFIRMATION: {
                const {
                    instruction, form_data: formData, document_image: documentImage, document_images: documentImages,
                } = subStageData[stageData];

                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{instruction}</div>
                        <div className='vkyc-image-container'>
                            <AutoScaleImageViewer
                                url={documentImages || documentImage?.presigned_read_url}
                                baseContainerClass='vkyc-image-placeholder'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                                arrayContainerClass='vkyc-image-array mr-30'
                                pdfContainerClass='vkyc-pdf-container'
                                carouselScrollValue={CAROUSEL_SCROLL_VALUE}
                                handleReload={handleImageReload}
                            />
                            <div className='ml-30'>
                                <div>
                                    {
                                        formData?.data
                                        && Object.keys(formData?.data || {})?.map((key) => (
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>{key}</div>
                                                <div className='vkyc-kv-value'>{formData?.data[key]}</div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className='frWrapper'>
                                    <Button
                                        primary
                                        v2
                                        label='Continue'
                                        onClick={() => {
                                            setSubStageStep(subStageStep + 1);
                                        }}
                                        extClasses='vkyc-step-option vkyc-step-option--yes'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            default: return <div>Something went wrong</div>;
        }
    };

    return (
        <div>
            {getNextComponent()}
        </div>
    );
};

export default DocVerificationStep;
