/**
 * @file Carousel Component
 */

import React, { useState, useEffect, useRef, memo } from 'react';
import classnames from 'classnames';

import './styles.scss';

// eslint-disable-next-line no-shadow
const ScrollDirection = {
    Left: 'left',
    Right: 'right',
};

const Carousel = (props) => {
    const {
        children, leftArrow, rightArrow,
        leftScrollValue, rightScrollValue, extClasses,
    } = props;

    const scrollableRef = useRef(null);

    const [leftButtonVisible, setLeftButtonVisible] = useState(false);
    const [rightButtonVisible, setRightButtonVisible] = useState(true);

    const rightArrowClasses = classnames('carousel-arrow', {
        'carousel-arrow__hide': !rightButtonVisible,
    }, extClasses && extClasses.rightArrow);
    const leftArrowClasses = classnames('carousel-arrow', {
        'carousel-arrow__hide': !leftButtonVisible,
    }, extClasses && extClasses.leftArrow);

    const onScroll = (direction) => () => {
        if (scrollableRef.current) {
            const scrollValue = direction === ScrollDirection.Left ? -leftScrollValue : rightScrollValue;

            (scrollableRef.current).scrollBy({
                left: scrollValue,
                behavior: 'smooth',
            });
        }
    };

    /**
     * Handle scroll event on the scrollable container
     * and update the visibility of the left and right arrows.
     */
    useEffect(() => {
        const scrollableContainer = scrollableRef.current;

        if (scrollableContainer) {
            const handleScroll = () => {
                const { scrollLeft, scrollWidth, clientWidth } = scrollableContainer;

                setLeftButtonVisible(scrollLeft > 0);
                setRightButtonVisible(scrollLeft < scrollWidth - clientWidth);
            };

            scrollableContainer.addEventListener('scroll', handleScroll);

            return () => {
                scrollableContainer.removeEventListener('scroll', handleScroll);
            };
        }

        return undefined; // Added this line to satisfy the return requirement
    }, []);

    return (
        <div className='carousel'>
            <div ref={scrollableRef} className='carousel-cont'>
                {children}
            </div>
            <div onClick={onScroll(ScrollDirection.Left)} role='presentation' className={leftArrowClasses}>
                {leftArrow}
            </div>
            <div onClick={onScroll(ScrollDirection.Right)} role='presentation' className={rightArrowClasses}>
                {rightArrow}
            </div>
        </div>
    );
};

/**
 * The Carousel component provides a customizable carousel UI for displaying
 * a set of items that can be horizontally scrolled using left and right arrow navigation.
 *
 * Note: The arrow navigation is designed for desktop view and is not visible on mobile devices.
 *
 * @example:
 *  const scrollableRef = useRef<HTMLDivElement>(null);
 *  const leftArrow = <LeftArrow />;
 *  const rightArrow = <RightArrow />;
 *
 *  return (
 *      <Carousel
 *        scrollableRef={scrollableRef}
 *        leftArrow={leftArrow}
 *        rightArrow={rightArrow}
 *        leftScrollValue={140}
 *        rightScrollValue={140}
 *      >
 *          <div>Item 1</div>
 *          <div>Item 2</div>
 *          <div>Item 3</div>
 *      </Carousel>
 *  );
 */
export default memo(Carousel);
