// The above comment must be removed while pushing the code to production
import React, { useEffect, useMemo, useRef } from 'react';
import { useParticipant } from '@videosdk.live/react-sdk';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { clearParticipantAudioStream, setParticipantAudioStream } from '../actions';

const ParticipantView = (props) => {
    const micRef = useRef(null);
    const {
        webcamStream, micStream, webcamOn, micOn, isLocal,
    } = useParticipant(props?.participantId);

    const dispatch = useDispatch();

    const {
        height, width, recordAudio = false, setParticipantLoadingStatus, isCustomerVideo, showCropBox,
    } = props;

    // eslint-disable-next-line consistent-return
    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
        if (recordAudio && micRef.current && micOn && micStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(micStream.track);
            dispatch(setParticipantAudioStream(mediaStream));
        }
        return () => {
            dispatch(clearParticipantAudioStream());
        };
    }, [micStream]);

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);

                micRef.current.srcObject = mediaStream;
                micRef.current
                    .play()
                    .catch((error) => console.error('videoElem.current.play() failed', error));
            } else {
                micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn]);

    return (
        <div className={classNames(
            'vkyc-customer-video-player-parent-common',
            { 'vkyc-customer-video-player-parent-common-customer': isCustomerVideo },
            { 'vkyc-customer-video-player-parent-common-agent': !isCustomerVideo },
        )}
        >
            {/*
                eslint-disable-next-line
            */}
            <audio ref={micRef} autoPlay playsInline muted={isLocal} />
            {webcamOn && (
                <React.Fragment>
                    {
                        isCustomerVideo
                        && showCropBox
                        && (<div className='vkyc-customer-video-player-parent-common-overlay' />)
                    }
                    <ReactPlayer
                        playsinline // extremely crucial prop - needed by videosdk
                        pip={false}
                        light={false}
                        controls={false}
                        muted
                        playing
                        onStart={() => {
                            if (!isLocal && typeof setParticipantLoadingStatus === 'function') {
                                /**
                                 * To manage delays in customer joining, we update the status when the video starts.
                                 * This block of code will only execute for the customer. An event is used to update the loader,
                                 * reflecting the customer's joining status to the agent.
                                 * To test this we have dropped the packet's of videosdk.live media stream and turn server stream.
                                 * we have blocked the media stream and turn server stream at os level
                                 * steps to drop ip's are as follows:
                                 *  sudo nano /etc/pf.conf // you can use any editor nano, vim etc
                                 *  add the following lines
                                 *  block drop from any to 13.233.236.81 // to block media stream
                                 *  block drop from any to 13.233.220.41 // to block turn server stream
                                 *
                                 * to activate the changes run the following command
                                 * sudo pfctl -e -f /etc/pf.conf
                                 * ref: https://epifi.slack.com/archives/C076L5DGMKP/p1722610766651709?thread_ts=1721125752.192379&cid=C076L5DGMKP
                                 *
                                 */
                                setParticipantLoadingStatus();
                            }
                        }}
                        url={videoStream}
                        //
                        onError={(err) => {
                            // eslint-disable-next-line no-console
                            console.log(err, 'participant video error');
                        }}
                        height={height}
                        width={width}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default ParticipantView;
