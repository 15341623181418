import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Toast from '../components/Toast';

const TOAST_NOTIFICATION = 'toast-notification';
const TOAST_SUCCESS = 'toast-success';
const TOAST_ERROR = 'toast-error';
const TOAST_WARNING = 'toast-warning';

export const TOAST_CONFIG = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: false,
    newestOnTop: false,
};

function getConfig(type, autoClose, customConfig, extClasses) {
    const config = {
        autoClose,
        ...customConfig,
    };
    const className = TOAST_NOTIFICATION;
    let typeClassName = '';

    switch (type) {
        case 'success':
            typeClassName = TOAST_SUCCESS;
            break;
        case 'error':
            typeClassName = TOAST_ERROR;
            break;
        case 'warning':
            typeClassName = TOAST_WARNING;
            break;
        default:
            break;
    }
    return {
        ...config,
        className: classNames(className, typeClassName, extClasses),
    };
}

function getIconType(type) {
    return type === 'success' ? 'check' : 'warning';
}
const dismissAll = () => toast.dismiss();

export default function toastify(
    message,
    // eslint-disable-next-line default-param-last
    type = 'warning',
    autoClose,
    config,
    extClasses,
    ...rest
) {
    toast(
        <Toast
            content={message}
            iconType={getIconType(type)}
            dismissAll={dismissAll}
        />,
        {
            ...getConfig(type, autoClose, config, extClasses),
            ...rest,
        },
    );
}
