import { APP_V2_KEY } from '../AppV2/constants';

export const VKYC_KEY = `${APP_V2_KEY}/VKYC`;

export const GET_AVAILABLE_CALLS = `${VKYC_KEY}/GET_AVAILABLE_CALLS`;
export const SET_AVAILABLE_CALLS = `${VKYC_KEY}/SET_AVAILABLE_CALLS`;
export const ERR_AVAILABLE_CALLS = `${VKYC_KEY}/ERR_AVAILABLE_CALLS`;

export const GET_INITIATE_VKYC_CALL = `${VKYC_KEY}/GET_INITIATE_VKYC_CALL`;
export const SET_INITIATE_VKYC_CALL = `${VKYC_KEY}/SET_INITIATE_VKYC_CALL`;
export const ERR_INITIATE_VKYC_CALL = `${VKYC_KEY}/ERR_INITIATE_VKYC_CALL`;

export const GET_CONCLUDE_VKYC_CALL = `${VKYC_KEY}/GET_CONCLUDE_VKYC_CALL`;
export const SET_CONCLUDE_VKYC_CALL = `${VKYC_KEY}/SET_CONCLUDE_VKYC_CALL`;
export const ERR_CONCLUDE_VKYC_CALL = `${VKYC_KEY}/ERR_CONCLUDE_VKYC_CALL`;

export const GET_CAPTURE_SCREENSHOT = `${VKYC_KEY}/GET_CAPTURE_SCREENSHOT`;
export const SET_CAPTURE_SCREENSHOT = `${VKYC_KEY}/SET_CAPTURE_SCREENSHOT`;
export const ERR_CAPTURE_SCREENSHOT = `${VKYC_KEY}/ERR_CAPTURE_SCREENSHOT`;

export const GET_PERFORM_CLIENT_ACTION = `${VKYC_KEY}/GET_PERFORM_CLIENT_ACTION`;
export const SET_PERFORM_CLIENT_ACTION = `${VKYC_KEY}/SET_PERFORM_CLIENT_ACTION`;
export const ERR_PERFORM_CLIENT_ACTION = `${VKYC_KEY}/ERR_PERFORM_CLIENT_ACTION`;

export const GET_ONBOARDING_STAGES = `${VKYC_KEY}/GET_ONBOARDING_STAGES`;
export const SET_ONBOARDING_STAGES = `${VKYC_KEY}/SET_ONBOARDING_STAGES`;
export const ERR_ONBOARDING_STAGES = `${VKYC_KEY}/ERR_ONBOARDING_STAGES`;

export const GET_VKYC_STEP = `${VKYC_KEY}/GET_VKYC_STEP`;
export const SET_VKYC_STEP = `${VKYC_KEY}/SET_VKYC_STEP`;
export const ERR_VKYC_STEP = `${VKYC_KEY}/ERR_VKYC_STEP`;

export const GET_VKYC_FLOW_STEP = `${VKYC_KEY}/GET_VKYC_FLOW_STEP`;
export const SET_VKYC_FLOW_STEP = `${VKYC_KEY}/SET_VKYC_FLOW_STEP`;
export const ERR_VKYC_FLOW_STEP = `${VKYC_KEY}/ERR_VKYC_FLOW_STEP`;

export const SET_VKYC_QUESTION_ANSWER = `${VKYC_KEY}/SET_VKYC_QUESTION_ANSWERS`;

export const SET_VKYC_TITLE = `${VKYC_KEY}/SET_VKYC_TITLE`;

export const SET_VKYC_IMAGE_URL = `${VKYC_KEY}/SET_VKYC_IMAGE_URL`;

export const SET_VKYC_CAMERA_ORIENTATION = `${VKYC_KEY}/SET_VKYC_CAMERA_ORIENTATION`;

export const GET_EXTRACT_DOCUMENT_DETAILS = `${VKYC_KEY}/GET_EXTRACT_DOCUMENT_DETAILS`;
export const SET_EXTRACT_DOCUMENT_DETAILS = `${VKYC_KEY}/SET_EXTRACT_DOCUMENT_DETAILS`;
export const ERR_EXTRACT_DOCUMENT_DETAILS = `${VKYC_KEY}/ERR_EXTRACT_DOCUMENT_DETAILS`;

export const GET_MATCH_SCORE = `${VKYC_KEY}/GET_MATCH_SCORE`;
export const SET_MATCH_SCORE = `${VKYC_KEY}/SET_MATCH_SCORE`;
export const ERR_MATCH_SCORE = `${VKYC_KEY}/ERR_MATCH_SCORE`;

export const GET_END_VKYC_CALL = `${VKYC_KEY}/GET_END_VKYC_CALL`;
export const SET_END_VKYC_CALL = `${VKYC_KEY}/SET_END_VKYC_CALL`;
export const ERR_END_VKYC_CALL = `${VKYC_KEY}/ERR_END_VKYC_CALL`;

export const GET_GENERATE_VKYC_REPORT = `${VKYC_KEY}/GET_GENERATE_VKYC_REPORT`;
export const SET_GENERATE_VKYC_REPORT = `${VKYC_KEY}/SET_GENERATE_VKYC_REPORT`;
export const ERR_GENERATE_VKYC_REPORT = `${VKYC_KEY}/ERR_GENERATE_VKYC_REPORT`;

export const UPLOAD_SCREEN_RECORDING = `${VKYC_KEY}/UPLOAD_SCREEN_RECORDING`;
export const SET_UPLOAD_SCREEN_RECORDING = `${VKYC_KEY}/SET_UPLOAD_SCREEN_RECORDING`;
export const ERR_UPLOAD_SCREEN_RECORDING = `${VKYC_KEY}/ERR_UPLOAD_SCREEN_RECORDING`;

export const SET_LOADER_STATE = `${VKYC_KEY}/SET_LOADER_STATE`;

export const SET_PARTICIPANT_AUDIO_STREAM = `${VKYC_KEY}/SET_PARTICIPANT_AUDIO_STREAM`;
export const CLEAR_PARTICIPANT_AUDIO_STREAM = `${VKYC_KEY}/CLEAR_PARTICIPANT_AUDIO_STREAM`;
export const SET_CURRENT_PAGE = `${VKYC_KEY}/SET_CURRENT_PAGE`;

export const SET_AUDIO_MUTE_FLAG = `${VKYC_KEY}/SET_AUDIO_MUTE_FLAG`;
export const SET_REJECTION_REASONS = `${VKYC_KEY}/SET_REJECTION_REASONS`;
export const SET_DEVICE_PERMISSIONS = `${VKYC_KEY}/SET_DEVICE_PERMISSIONS`;
export const SET_CURRENT_STAGE_POINTER = `${VKYC_KEY}/SET_CURRENT_STAGE_POINTER`;

export const GET_VKYC_AVAILABLE_CALLS_COUNT = `${VKYC_KEY}/GET_VKYC_AVAILABLE_CALLS_COUNT`;
export const SET_VKYC_AVAILABLE_CALL_COUNT = `${VKYC_KEY}/SET_VKYC_AVAILABLE_CALL_COUNT`;
export const SET_LOCAL_FILE_STORED_STATUS = `${VKYC_KEY}/SET_LOCAL_FILE_STORED_STATUS`;

export const SET_PARTICIPANT_JOINING_STATUS = `${VKYC_KEY}/SET_PARTICIPANT_JOINING_STATUS`;

export const SET_FACEMATCH_IMAGE_STATUS = `${VKYC_KEY}/SET_FACEMATCH_IMAGE_STATUS`;

export const SET_MEETING_LOADER_STATE = `${VKYC_KEY}/SET_MEETING_LOADER_STATE`;

export const LOG_VKYC_STAGE_PROGRESS = `${VKYC_KEY}/LOG_VKYC_STAGE_PROGRESS`;

export const SET_ERROR_VIEW = `${VKYC_KEY}/SET_ERROR_VIEW`;

export const CAMERA_ORIENTATION = {
    front: 'CAMERA_TYPE_FRONT',
    back: 'CAMERA_TYPE_REAR',
};

export const CLIENT_ACTION = {
    Flip: 'REQUEST_CLIENT_ACTION_TYPE_FLIP_CAMERA',
};

export const ONBOARIDNG_STAGE_TYPE = {
    FACE_MATCH: 'ONBOARDING_STAGE_FACE_MATCH',
    IMAGE_CAPTURE: 'ONBOARDING_STAGE_IMAGE_CAPTURE',
    OCR_EXTRACTION: 'ONBOARDING_STAGE_OCR_EXTRACTION',
    OCR_VALIDATION: 'ONBOARDING_STAGE_OCR_VALIDATION',
    DOCUMENT_DETAILS_CONFIRMATION: 'ONBOARDING_STAGE_DOCUMENT_DETAILS_CONFIRMATION',
};

export const QUESTION_ANSWER_VALUE = {
    YES: 'Yes',
    NO: 'No',
};

export const VKYCDocumentType = {
    PAN: 'DOCUMENT_TYPE_PAN',
    PASSPORT: 'DOCUMENT_TYPE_PASSPORT',
    COUNTRY_ID: 'DOCUMENT_TYPE_COUNTRY_ID',
    EMIRATES_ID: 'DOCUMENT_TYPE_EMIRATES_ID',
};

export const VKYC_STAGES = {
    LIVENESS: 'STAGE_NAME_LIVENESS',
    LOCATION: 'STAGE_NAME_LOCATION',
    FACE_CAPTURE: 'STAGE_NAME_FACE_CAPTURE',
    PAN_CAPTURE: 'STAGE_NAME_PAN_CAPTURE',
    PASSPORT_CAPTURE: 'STAGE_NAME_PASSPORT_CAPTURE',
    EMIRATES_ID_CAPTURE: 'STAGE_NAME_EMIRATES_ID_CAPTURE',
    DOCUMENT_VERIFICATION: 'STAGE_NAME_CKYC_DOCUMENT_VERIFICATION',
    VKYC_REPORT: 'STAGE_NAME_VKYC_REPORT',
};

export const FLOW_PROGRESS = {
    INITIATE_CALL: 'INITIATE_CALL',
    IN_PROGRESS: 'IN_PROGRESS',
    END_CALL_SUCCESS: 'END_CALL_SUCCESS',
    END_CALL_FAILED: 'END_CALL_FAILED',
    AWS_UPLOAD_FAILED: 'AWS_UPLOAD_FAILED',
    AWS_UPLOAD_SUCCESS: 'AWS_UPLOAD_SUCCESS',
};

export const EVENT_LOG_INTERVAL = 10000;

export const CAROUSEL_SCROLL_VALUE = 380;

export const stagePointerPayload = (meetingId, stepNumber, subStepNumber) => ({ meetingId, stepNumber, subStepNumber });

const protocol = window.location.hostname === 'localhost' ? 'http' : 'https';
const { host } = window.location;

export const FAVICON_URL = `${protocol}://${host}/favicon.ico`;
