/**
 *
 * UserAuth
 *
 */

import React, { memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import { Button, FormWithSingleInput } from '../../../components';
import { UserDetailsContext } from '../../../context';
import { DEFAULT_TICKET_PATH } from '../../App/routes';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import TicketAndUserInfo from '../TicketAndUserInfo';
import { MODERATELY_SENSITIVE_SCREENS, SENSITIVE_SCREENS } from '../constants';
import {
    setAuthFactorValue, verifyAuthFactor, setAsyncAuthInfo, setTicketId, getInitialAuthFactor,
    discardActionAuthInfo,
} from '../actions';

import './style.scss';

// eslint-disable-next-line consistent-return
const UserAuth = (props) => {
    const {
        index, type, label, nextAction, warningLabel, handleRedirect, disableTicketUserInfo,
    } = props;
    const agentInfo = useContext(UserDetailsContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const ticketSelector = createSelector((state) => state, (sel) => sel.toJS().tickets.ticketList[index]);
    const ticket = useSelector(ticketSelector);
    const {
        ticketId, basicInfo: {
            source, phoneNumber, ticketPhoneNumber, name,
        },
        hasConfirmedUserInfo, userInfo, userId, authInfo, enableCheckStatusCta, isAuthUpgradeInProgress,
    } = ticket;
    const { screen } = authInfo;
    const ticketAndUserInfo = {
        ticketId,
        source,
        phoneNumber: ticketPhoneNumber || phoneNumber,
        name: hasConfirmedUserInfo ? userInfo.name : name,
    };

    const refreshAuthFactor = () => {
        dispatch(getInitialAuthFactor({ ticketId, userId, index }));
    };

    const resetTicketFlow = () => {
        if (type) {
            dispatch(discardActionAuthInfo({ type, index }));
        } else {
            dispatch(setTicketId({ ticketId: '', index }));
            history.push(DEFAULT_TICKET_PATH);
        }
    };

    const onAuthFactorTextChange = (authFactor) => (event) => {
        let value;

        if (authFactor.formType === 'dob') {
            value = event;
        } else {
            value = event.target.value;
        }

        dispatch(setAuthFactorValue({ authFactor, index, value }));
    };

    const onAuthFactorOtherChange = (authFactor) => (key) => (event) => {
        dispatch(setAuthFactorValue({
            authFactor, index, key, value: event.target.value,
        }));
    };

    const setupWebSocket = () => {
        const proxyRoute = window.location.hostname === 'localhost' ? '/socket' : '';
        const { host } = window.location;

        const websocket = new WebSocket(
            `${process.env.REACT_APP_WS_URL_PREFIX}${host}${proxyRoute}`,
        );

        websocket.onopen = () => {
            websocket.send(JSON.stringify({
                type: 'auth-factor',
                data: {
                    ticketId,
                    emailId: agentInfo.basicInfo.emailId,
                },
            }));
        };

        websocket.onmessage = (evt) => {
            const parsedData = JSON.parse(evt.data);
            // eslint-disable-next-line no-shadow
            const { type, data } = parsedData;

            if (type === 'auth-factor') {
                dispatch(setAsyncAuthInfo(data));

                if (SENSITIVE_SCREENS.includes(data.screen) || MODERATELY_SENSITIVE_SCREENS.includes(data.screen)) {
                    if (handleRedirect) {
                        handleRedirect();
                    } else {
                        history.push(`${DEFAULT_TICKET_PATH}${ticketId}/banking/profile`);
                    }
                }

                websocket.close();
            }
        };

        websocket.onclose = () => {
        };
    };

    const onAuthFactorSubmit = (authFactor, unavailable = false) => () => {
        dispatch(verifyAuthFactor({
            authFactor, userId, index, unavailable, ticketId, setupWebSocket, type, nextAction, handleRedirect,
        }));
    };

    if (screen === 1 || isAuthUpgradeInProgress) { // auth screen
        const { reviewedAuthFactorList = [], activeAuthFactorList = [], inactiveAuthFactorList = [] } = authInfo;

        return (
            <React.Fragment>
                {
                    !disableTicketUserInfo
                        ? (
                            <React.Fragment>
                                {
                                    !type ? (
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                        <TicketAndUserInfo {...ticketAndUserInfo} />
                                    ) : null
                                }
                                {
                                    type
                                        ? (
                                            <div className='user-auth-container user-auth-container--header'>
                                                <img
                                                    className='user-auth-backarrow'
                                                    src={backArrow}
                                                    onClick={resetTicketFlow}
                                                    alt='Back Arrow'
                                                    role='presentation'
                                                />
                                                <div className='user-auth-label'>{label}</div>
                                            </div>
                                        ) : null
                                }
                            </React.Fragment>
                        )
                        : null
                }
                <div className='user-auth-container'>
                    <div className='user-auth-desc'>
                        We need to confirm the following details from the user before we can
                        {!type ? ' show the required information' : ' perform the above action'}
                    </div>
                    {
                        reviewedAuthFactorList.map((item) => (
                            <FormWithSingleInput
                                key={item.name}
                                extClasses={{
                                    container: 'auth-factor-form-wrapper',
                                }}
                                label={item.formLabel}
                                labelId={item.formLabelId}
                                formType={item.formType}
                                description={item.formDescription}
                                input={{
                                    value: item.formValue,
                                    disabled: true,
                                }}
                                statusLabel={item.statusLabel}
                                errLabel={item.errLabel}
                            />
                        ))
                    }
                    {
                        activeAuthFactorList.map((item) => (
                            <FormWithSingleInput
                                key={item.name}
                                extClasses={{
                                    container: 'auth-factor-form-wrapper',
                                }}
                                label={item.formLabel}
                                labelId={item.formLabelId}
                                formType={item.formType}
                                description={item.formDescription}
                                input={{
                                    value: item.formValue,
                                    onChange: (item.formType === 'text' || item.formType === 'dob')
                                        ? onAuthFactorTextChange(item)
                                        : onAuthFactorOtherChange(item),
                                    disabled: item.async,
                                    showYearDropdown: item.formType === 'dob',
                                    yearDropdownItemNumber: item.formType === 'dob' && 80,
                                    maxDate: item.formType === 'dob' && new Date(),
                                }}
                                cta={{
                                    // eslint-disable-next-line no-nested-ternary
                                    primaryLabel: item.errLabel ? 'Retry' : (item.async ? 'Trigger' : 'Verify'),
                                    onPrimaryClick: onAuthFactorSubmit(item),
                                    secondaryLabel: item.async ? 'User cannot access' : 'User doesn\'t know',
                                    onSecondaryClick: onAuthFactorSubmit(item, true),
                                    tertiaryLabel: (item.async && enableCheckStatusCta) ? 'Check Status' : '',
                                    onTertiaryClick: refreshAuthFactor,
                                }}
                                errLabel={item.errLabel}
                            />
                        ))
                    }
                    {
                        inactiveAuthFactorList.map((item) => (
                            <FormWithSingleInput
                                key={item.name}
                                extClasses={{
                                    container: 'auth-factor-form-wrapper',
                                }}
                                label={item.formLabel}
                                labelId={item.formLabelId}
                                description={item.formDescription}
                                input={{
                                    value: '',
                                    disabled: true,
                                }}
                                cta={{
                                    primaryLabel: item.async ? 'Trigger' : 'Verify',
                                    primaryDisabled: true,
                                    secondaryLabel: item.async ? 'User cannot access' : 'User doesn\'t know',
                                    secondaryDisabled: true,
                                }}
                            />
                        ))
                    }
                    {
                        activeAuthFactorList.length === 0 ? (
                            <React.Fragment>
                                <div className='user-auth-warning-label'>
                                    {
                                        warningLabel
                                        || `User seems to have run out of all ways of authentication and
                                        ${!type
                                ? ' user has not been\n authenticated yet. Please follow SOP for such cases.'
                                : ' not allowed to perform the above action'}`
                                    }
                                </div>
                                <Button
                                    warning
                                    label='Close and Go Back'
                                    extClasses='user-auth-warning-cta'
                                    onClick={resetTicketFlow}
                                />
                            </React.Fragment>
                        ) : null
                    }
                </div>
            </React.Fragment>
        );
    }

    // TODO: add landing page screen
    if (screen === 2) { // landing page screen

    }

    // TODO: add auth error screen
    if (screen === 7) { // auth error screen

    }
};

export default memo(UserAuth);
