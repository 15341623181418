/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading, import/no-named-as-default-member, import/no-named-as-default */
/**
 * @file Manages the constants for App component
 */

// eslint-disable-next-line import/no-cycle
import AgentManagement from '../AgentManagement';
import LogsInfo from '../LogsInfo';
// eslint-disable-next-line import/no-cycle
import Tickets from '../Tickets';
import Developer from '../Developer';
import PayoutRequests from '../PayoutRequests';
import WaitlistApproval from '../WaitlistApproval';
// eslint-disable-next-line import/no-cycle
import Login from '../Login';
import WaitlistDetails from '../WaitlistDetails';
import BizAdmin from '../BizAdmin';
// eslint-disable-next-line import/no-cycle
import AdminTools from '../AdminTools';
import WebDeveloper from '../WebDeveloper';
import AccountOps from '../AccountOps';
import SalaryAccountOps from '../SalaryAccountOps';
import TransactionRisk from '../TransactionRisk';
import SalaryWhitelistB2B from '../SalaryWhitelistB2B';

import {
    ROOT_PATH,
    AGENT_PATH,
    LOGS_PATH,
    PAYOUT_REQUESTS_PATH,
    DEFAULT_TICKET_PATH,
    DEVELOPER_PATH,
    WAITLIST_APPROVAL_PATH,
    LOGIN_PATH,
    FEDERAL_PATH,
    CHECK_WAITLIST_DETAILS_PATH,
    // FIT_RULES_PATH,
    BIZ_ADMIN_PATH,
    ADMIN_TOOLS_PATH,
    WEB_DEVELOPER_PATH,
    ACCOUNT_OPS_PATH,
    SALARY_ACCOUNT_OPS_PATH,
    RISK_OPS_RISK_PATH,
    SALARY_WHITELIST_B2B_PATH,
    SHERLOCK_V2,
} from './routes';
import FederalOps from '../FederalOps';

export const ACCESS_LEVELS = {
    admin: 'ADMIN',
    agent: 'AGENT',
    developer: 'DEVELOPER',
    qa: 'QA',
    viewLivenessVideo: 'VIEW_LIVENESS_VIDEO',
    superAdmin: 'SUPER_ADMIN',
    waitlistApprover: 'WAITLIST_APPROVER',
    waitlistAdminApprover: 'WAITLIST_ADMIN_APPROVER',
    qaLead: 'QA_LEAD',
    federalAgent: 'FEDERAL_AGENT',
    riskOps: 'RISK_OPS',
    fitAdmin: 'FIT_ADMIN',
    adminRestricted: 'ADMIN_RESTRICTED',
    bizAdmin: 'BIZ_ADMIN',
    recurringPaymentDev: 'RECURRING_PAYMENT_DEV',
    wealthOps: 'WEALTH_OPS',
    webDeveloper: 'WEB_DEVELOPER',
    accountOps: 'ACCOUNT_OPS',
    riskOpsAdmin: 'RISK_OPS_ADMIN',
    salaryAccountOps: 'SALARY_DATA_OPS',
    rewardsAdmin: 'REWARDS_ADMIN',
    bizAdminRestricted: 'BIZ_ADMIN_RESTRICTED',
    dataOps: 'DATA_OPS',
    federalInwardRemitter: 'FEDERAL_INWARD_REMITTER',
    federalOutwardRemitter: 'FEDERAL_OUTWARD_REMITTER',
    federalMasterRemitter: 'FEDERAL_MASTER_REMITTER',
    federalMasterRemittanceOps: 'FEDERAL_MASTER_REMITTANCE_OPS',
    riskEng: 'RISK_ENG',
    jumpSensitiveVendorResponse: 'JUMP_SENSITIVE_VENDOR_RESPONSE',
    jumpOps: 'JUMP_OPS',
    usStocksOps: 'US_STOCKS_OPS',
    federalLoanLivenessReviewer: 'FEDERAL_LOAN_LIVENESS_REVIEWER',
    riskOpsTxn: 'RISK_OPS_TXN',
    kycAnalyst: 'KYC_ANALYST',
    financeAdmin: 'FINANCE_ADMIN',
    salaryWhitelistB2B: 'SALARY_WHITELIST_B2B',
    userOutcall: 'USER_OUTCALL',
    kycAgentAdmin: 'KYC_AGENT_ADMIN',
    dataRetriever: 'DATA_RETRIEVER',
    salaryAdmin: 'SALARY_ADMIN',
    segmentationAdmin: 'SEGMENTATION_ADMIN',
    usStocksSensitiveData: 'USSTOCKS_SENSITIVE_DATA',
    cxSupportLlmTester: 'CX_SUPPORT_LLM_TESTER',
    vkycCallAgent: 'VKYC_CALL_AGENT',
    vkycCallAuditor: 'VKYC_CALL_AUDITOR',
    stockGuardianVkycCallAgent: 'STOCK_GUARDIAN_VKYC_CALL_AGENT',
    stockGuardianVkycCallAuditor: 'STOCK_GUARDIAN_VKYC_CALL_AUDITOR',
};

const ACCESS_CATEGORIES = {
    role1: [ // 'admin', 'qa', 'agent', 'super admin', 'qa lead', 'admin restricted'
        ACCESS_LEVELS.admin, ACCESS_LEVELS.qa, ACCESS_LEVELS.agent, ACCESS_LEVELS.superAdmin,
        ACCESS_LEVELS.qaLead, ACCESS_LEVELS.adminRestricted,
    ],
    /**
     * 'developer', 'view liveness video', 'risk ops', 'fit admin', 'recurring payment dev', 'wealth risk ops',
     * 'risk ops admin', 'rewards admin', 'risk eng', 'jump sensitive vendor response', 'jump ops', 'transaction risk analyst', 'kyc analyst'
     * 'finance admin'
     */
    role2: [
        ACCESS_LEVELS.developer, ACCESS_LEVELS.viewLivenessVideo, ACCESS_LEVELS.riskOps, ACCESS_LEVELS.riskOpsAdmin,
        ACCESS_LEVELS.fitAdmin, ACCESS_LEVELS.recurringPaymentDev, ACCESS_LEVELS.wealthOps, ACCESS_LEVELS.rewardsAdmin, ACCESS_LEVELS.dataOps,
        ACCESS_LEVELS.riskEng, ACCESS_LEVELS.jumpSensitiveVendorResponse, ACCESS_LEVELS.jumpOps, ACCESS_LEVELS.usStocksOps,
        ACCESS_LEVELS.riskOpsTxn, ACCESS_LEVELS.kycAnalyst, ACCESS_LEVELS.financeAdmin, ACCESS_LEVELS.userOutcall, ACCESS_LEVELS.kycAgentAdmin,
        ACCESS_LEVELS.dataRetriever, ACCESS_LEVELS.salaryAdmin, ACCESS_LEVELS.segmentationAdmin, ACCESS_LEVELS.usStocksSensitiveData,
        ACCESS_LEVELS.cxSupportLlmTester,
    ], // all developer and operations related flows
    role3: [ACCESS_LEVELS.waitlistApprover, ACCESS_LEVELS.waitlistAdminApprover], // 'waitlist approver', 'waitlist admin approver'
    role4: [ // all roles used for any federal flow
        ACCESS_LEVELS.federalAgent,
        ACCESS_LEVELS.federalInwardRemitter,
        ACCESS_LEVELS.federalOutwardRemitter,
        ACCESS_LEVELS.federalMasterRemitter,
        ACCESS_LEVELS.federalLoanLivenessReviewer,
        ACCESS_LEVELS.federalMasterRemittanceOps,
    ], // 'all federal related flows'
    role5: [ACCESS_LEVELS.bizAdmin, ACCESS_LEVELS.bizAdminRestricted], // 'biz admin', 'biz admin restricted'
    role6: [ACCESS_LEVELS.webDeveloper], // 'web'
    role7: [ACCESS_LEVELS.accountOps], // 'account ops'
    role8: [ACCESS_LEVELS.salaryAccountOps], // 'salary account ops'
    role9: [ACCESS_LEVELS.salaryWhitelistB2B], // 'salary whitelist b2b'
    role10: [
        ACCESS_LEVELS.vkycCallAgent,
        ACCESS_LEVELS.vkycCallAuditor,
        ACCESS_LEVELS.stockGuardianVkycCallAgent,
        ACCESS_LEVELS.stockGuardianVkycCallAuditor,
    ], // sherlock v2
};

const getRedirectPathForRoot = (accessLevel) => {
    if (ACCESS_CATEGORIES.role1.includes(accessLevel)) {
        return DEFAULT_TICKET_PATH;
    }

    if (ACCESS_CATEGORIES.role2.includes(accessLevel)) {
        return DEVELOPER_PATH;
    }

    if (ACCESS_CATEGORIES.role3.includes(accessLevel)) {
        return WAITLIST_APPROVAL_PATH;
    }

    if (ACCESS_CATEGORIES.role4.includes(accessLevel)) {
        return FEDERAL_PATH;
    }

    if (ACCESS_CATEGORIES.role5.includes(accessLevel)) {
        return BIZ_ADMIN_PATH;
    }

    if (ACCESS_CATEGORIES.role6.includes(accessLevel)) {
        return WEB_DEVELOPER_PATH;
    }

    if (ACCESS_CATEGORIES.role7.includes(accessLevel)) {
        return ACCOUNT_OPS_PATH;
    }

    if (ACCESS_CATEGORIES.role8.includes(accessLevel)) {
        return SALARY_ACCOUNT_OPS_PATH;
    }

    if (ACCESS_CATEGORIES.role9.includes(accessLevel)) {
        return SALARY_WHITELIST_B2B_PATH;
    }

    if (ACCESS_CATEGORIES.role10.includes(accessLevel)) {
        return SHERLOCK_V2;
    }

    return LOGIN_PATH;
};

export const ROUTE_LIST = (accessLevel) => ([
    {
        path: ROOT_PATH,
        accessible: [
            ACCESS_LEVELS.admin,
            ACCESS_LEVELS.qa,
            ACCESS_LEVELS.agent,
            ACCESS_LEVELS.developer,
            ACCESS_LEVELS.viewLivenessVideo,
            ACCESS_LEVELS.superAdmin,
            ACCESS_LEVELS.waitlistApprover,
            ACCESS_LEVELS.waitlistAdminApprover,
            ACCESS_LEVELS.qaLead,
            ACCESS_LEVELS.fitAdmin,
            ACCESS_LEVELS.adminRestricted,
            ACCESS_LEVELS.bizAdmin,
        ],
        exact: true,
        redirect: getRedirectPathForRoot(accessLevel),
    },
    {
        path: AGENT_PATH,
        component: AgentManagement,
        accessible: [
            ACCESS_LEVELS.admin,
            ACCESS_LEVELS.qa,
            ACCESS_LEVELS.developer,
            ACCESS_LEVELS.viewLivenessVideo,
            ACCESS_LEVELS.superAdmin,
            ACCESS_LEVELS.waitlistApprover,
            ACCESS_LEVELS.waitlistAdminApprover,
            ACCESS_LEVELS.fitAdmin,
            ACCESS_LEVELS.bizAdmin,
        ],
    },
    {
        path: LOGS_PATH,
        component: LogsInfo,
        accessible: [
            ACCESS_LEVELS.admin,
            ACCESS_LEVELS.qa,
            ACCESS_LEVELS.superAdmin,
        ],
    },
    {
        path: ADMIN_TOOLS_PATH,
        component: AdminTools,
        accessible: [
            ACCESS_LEVELS.admin,
            ACCESS_LEVELS.qa,
            ACCESS_LEVELS.superAdmin,
        ],
    },
    {
        path: PAYOUT_REQUESTS_PATH,
        component: PayoutRequests,
        accessible: [
            ACCESS_LEVELS.admin,
            ACCESS_LEVELS.qa,
            ACCESS_LEVELS.superAdmin,
        ],
    },
    {
        path: CHECK_WAITLIST_DETAILS_PATH,
        component: WaitlistDetails,
        accessible: [
            ACCESS_LEVELS.admin,
            ACCESS_LEVELS.adminRestricted,
        ],
    },
    {
        path: DEFAULT_TICKET_PATH,
        component: Tickets,
        accessible: [
            ACCESS_LEVELS.admin,
            ACCESS_LEVELS.qa,
            ACCESS_LEVELS.agent,
            ACCESS_LEVELS.superAdmin,
            ACCESS_LEVELS.qaLead,
            ACCESS_LEVELS.adminRestricted,
        ],
    },
    {
        path: DEVELOPER_PATH,
        component: Developer,
        accessible: [
            ACCESS_LEVELS.developer,
            ACCESS_LEVELS.viewLivenessVideo,
            ACCESS_LEVELS.riskOps,
            ACCESS_LEVELS.fitAdmin,
            ACCESS_LEVELS.recurringPaymentDev,
            ACCESS_LEVELS.wealthOps,
            ACCESS_LEVELS.riskOpsAdmin,
            ACCESS_LEVELS.rewardsAdmin,
            ACCESS_LEVELS.dataOps,
            ACCESS_LEVELS.kycAgentAdmin,
            ACCESS_LEVELS.riskEng,
            ACCESS_LEVELS.jumpSensitiveVendorResponse,
            ACCESS_LEVELS.jumpOps,
            ACCESS_LEVELS.usStocksOps,
            ACCESS_LEVELS.kycAnalyst,
            ACCESS_LEVELS.riskOpsTxn,
            ACCESS_LEVELS.financeAdmin,
            ACCESS_LEVELS.userOutcall,
            ACCESS_LEVELS.dataRetriever,
            ACCESS_LEVELS.salaryAdmin,
            ACCESS_LEVELS.segmentationAdmin,
            ACCESS_LEVELS.usStocksSensitiveData,
            ACCESS_LEVELS.cxSupportLlmTester,
        ],
    },
    {
        path: WAITLIST_APPROVAL_PATH,
        component: WaitlistApproval,
        accessible: [
            ACCESS_LEVELS.waitlistApprover,
            ACCESS_LEVELS.waitlistAdminApprover,
        ],
    },
    {
        path: LOGIN_PATH,
        component: Login,
        accessible: [], // by keeping this empty, the logged in user cannot access login route.
        exact: true,
    },
    {
        path: FEDERAL_PATH,
        component: FederalOps,
        accessible: [
            ACCESS_LEVELS.federalAgent,
            ACCESS_LEVELS.federalInwardRemitter,
            ACCESS_LEVELS.federalOutwardRemitter,
            ACCESS_LEVELS.federalMasterRemitter,
            ACCESS_LEVELS.federalLoanLivenessReviewer,
            ACCESS_LEVELS.federalMasterRemittanceOps,
        ],
    },
    {
        path: BIZ_ADMIN_PATH,
        component: BizAdmin,
        accessible: [
            ACCESS_LEVELS.bizAdmin,
            ACCESS_LEVELS.bizAdminRestricted,
        ],
    },
    {
        path: WEB_DEVELOPER_PATH,
        component: WebDeveloper,
        accessible: [
            ACCESS_LEVELS.webDeveloper,
        ],
    },
    {
        path: ACCOUNT_OPS_PATH,
        component: AccountOps,
        accessible: [
            ACCESS_LEVELS.accountOps,
        ],
    },
    {
        path: SALARY_ACCOUNT_OPS_PATH,
        component: SalaryAccountOps,
        accessible: [
            ACCESS_LEVELS.salaryAccountOps,
        ],
    },
    {
        path: RISK_OPS_RISK_PATH,
        component: TransactionRisk,
        accessible: [
            ACCESS_LEVELS.riskOpsTxn,
            ACCESS_LEVELS.riskOps,
            ACCESS_LEVELS.kycAnalyst,
            ACCESS_LEVELS.userOutcall,
            ACCESS_LEVELS.riskOpsAdmin,
        ],
    },
    {
        path: SALARY_WHITELIST_B2B_PATH,
        component: SalaryWhitelistB2B,
        accessible: [
            ACCESS_LEVELS.salaryWhitelistB2B,
        ],
    },
]);

// key to be used for reducers & selectors for main app
export const APP_KEY = 'app';

// auth status
export const GET_AUTH_STATUS = 'app/App/GET_AUTH_STATUS';
export const SET_AUTH_STATUS = 'app/App/SET_AUTH_STATUS';
export const SET_AUTH_STATUS_LOADER = 'app/App/SET_AUTH_STATUS_LOADER';

// agent info
export const GET_AGENT_INFO = 'app/App/GET_AGENT_INFO';
export const SET_AGENT_INFO = 'app/App/SET_AGENT_INFO';
export const ERR_AGENT_INFO = 'app/App/ERR_AGENT_INFO';

export const SET_SECURITY_TOKEN = 'app/App/SET_SECURITY_TOKEN';

export const GET_AUTH_LOGOUT = 'app/App/GET_AUTH_LOGOUT';

export const GET_META_INFO = 'app/App/GET_META_INFO';
export const SET_META_INFO = 'app/App/SET_META_INFO';

export const GET_ACCESS_LEVEL_LIST = `app/${APP_KEY}/GET_ACCESS_LEVEL_LIST`;
export const SET_ACCESS_LEVEL_LIST = `app/${APP_KEY}/SET_ACCESS_LEVEL_LIST`;

export const GET_BANNER_LIST = `app/${APP_KEY}/GET_BANNER_LIST`;
export const SET_BANNER_LIST = `app/${APP_KEY}/SET_BANNER_LIST`;
export const ERR_BANNER_LIST = `app/${APP_KEY}/ERR_BANNER_LIST`;

export const SET_BANNERS_VISIBILITY = `app/${APP_KEY}/SET_BANNERS_VISIBILITY`;
