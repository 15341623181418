import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    MeetingProvider,
} from '@videosdk.live/react-sdk';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { path } from 'ramda';
import MeetingView from './MeetingView';
import { selectVkycData, selectVideoSdkConfig } from '../selectors';
import { setParticipantJoiningStatus } from '../actions';

const VideoStreamLayout = () => {
    const vkycData = useSelector(selectVkycData, shallowEqual);
    const dynamicVideoSdkConfig = useSelector(selectVideoSdkConfig, shallowEqual) || {};
    const participantJoiningStatus = path(['meetings', vkycData?.data?.meetingId, 'participantJoiningStatus'], vkycData);
    const dispatch = useDispatch();

    // This will set Meeting Id to null when meeting is left or ended
    const onMeetingLeave = () => {};

    const setParticipantLoadingStatus = () => {
        dispatch(setParticipantJoiningStatus({
            meetingId: vkycData?.data?.meetingId,
            status: true,
        }));
    };

    return (
        <div style={{ width: '100%' }}>
            {vkycData?.data?.authToken && vkycData?.data?.meetingId && (
                <MeetingProvider
                    config={{
                        debug: true,
                        meetingId: vkycData?.data?.meetingId,
                        micEnabled: true,
                        webcamEnabled: true,
                        preferredProtocol: 'UDP_OVER_TCP',
                        participantId: vkycData?.data?.participantId,
                        ...dynamicVideoSdkConfig,
                    }}
                    onParticipantLeft={() => {}}
                    token={vkycData?.data?.authToken}
                >
                    <MeetingView
                        meetingId={vkycData?.data?.meetingId}
                        meetingDetails={path(['meetings', vkycData?.data?.meetingId], vkycData)}
                        onMeetingLeave={onMeetingLeave}
                        participantJoiningStatus={participantJoiningStatus}
                        setParticipantLoadingStatus={setParticipantLoadingStatus}
                    />
                </MeetingProvider>
            )}
        </div>
    );
};

export default VideoStreamLayout;
