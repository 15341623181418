import React from 'react';
import Modal from 'react-modal';
import Button from '../../../components/Button';
import { ICONS_URL } from '../../../constants/assets';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1024,
    },
    content: {
        top: '8%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 32px 24px',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: '80%',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        minHeight: '80%',
        maxHeight: '80%',
        backgroundColor: '#ECEEF0',
        borderRadius: 19,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
};

const Remarks = (props) => {
    const {
        setRemarkState, remarkState, reviewReportAction, rejectedReasons,
    } = props;

    const { visible } = remarkState;

    const toggleAccordion = (event) => {
        const { parentElement, lastElementChild } = event.currentTarget;
        if (parentElement && parentElement.lastElementChild) {
            // Access the last child of the parent element
            const lastChild = parentElement.lastElementChild;

            // Toggle the display property of the last child

            if (lastChild.style.display === 'none') lastChild.style.display = 'flex';
            else lastChild.style.display = 'none';

            // Rotate the arrow icon based on the display property of the last child

            lastElementChild.style.transform = lastChild.style.display === 'none' ? 'rotate(0deg)' : 'rotate(180deg)';
        }
    };

    const toggleModal = () => {
        setRemarkState((prev) => ({
            ...prev, visible: !visible, rejectionReason: '', comment: '',
        }));
    };

    return (

        <Modal
            isOpen={visible}
            style={customStylesForModal}
            contentLabel='Add Remarks'
        >
            <div className='vkyc-remarks-containers'>
                <div>
                    <img
                        src={ICONS_URL.CLOSE}
                        alt='arrow-icon'
                        className='vkyc-remarks-containers-close-modal-icon'
                        onKeyUp={() => {}}
                        onClick={toggleModal}
                        role='presentation'
                    />
                </div>

                <div className='vkyc-remarks-accordion-modal'>
                    <div className='vkyc-remarks-accordion-modal-title'>Reasons/ Remarks for rejection</div>
                    <div className='vkyc-remarks-accordion-modal-sub-title'>Select one of the options below to end the call</div>
                </div>
                {
                    rejectedReasons?.map((category) => (
                        <div className='vkyc-remarks-accordion'>
                            <div
                                className='vkyc-remarks-accordion-header'
                                data-testId='accordion-header'
                                tabIndex='0'
                                onKeyUp={() => {}}
                                onClick={toggleAccordion}
                                role='button'
                            ><span>{category?.title}</span>
                                <img src={ICONS_URL.CHEVRON_BOTTOM} alt='arrow-icon' className='vkyc-remarks-accordion-header-arrow-icon' />
                            </div>
                            <div className='vkyc-remarks-accordion-body'>
                                {
                                    category?.rejection_reasons.map((reason) => (
                                        <React.Fragment>
                                            <div className='vkyc-remarks-accordion-item'>
                                                <input
                                                    type='radio'
                                                    id={reason?.rejection_reason_id}
                                                    name='accordion'
                                                    className='vkyc-remarks-accordion-radio'
                                                    onChange={() => setRemarkState((prev) => ({
                                                        ...prev,
                                                        rejectionReason: reason?.rejection_reason_id,
                                                        comment: '',
                                                    }
                                                    ))}
                                                />
                                                <label htmlFor={reason?.rejection_reason_id}>{reason?.rejection_reason}</label>
                                            </div>
                                            {
                                                /**
                                                 * If the selected reason is '*REASON_OTHER' then show the text area to enter the remarks
                                                 */
                                                reason?.rejection_reason_id.includes('REASON_OTHER')
                                                && remarkState?.rejectionReason.includes('REASON_OTHER') && (
                                                    <div className='vkyc-remarks-text-container'>
                                                        <div className='vkyc-remarks-field-label' />
                                                        <textarea
                                                            className='vkyc-remarks-text-container-textarea'
                                                            onChange={(event) => setRemarkState((prev) => ({
                                                                ...prev,
                                                                comment: event?.target?.value,
                                                            }))}
                                                            value={remarkState.comment}
                                                            placeholder='Enter your remarks here'
                                                        />
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }

                <div className='vkyc-remarks-action'>
                    <Button
                        secondary
                        label='Cancel'
                        onClick={() => setRemarkState((prev) => ({ ...prev, visible: false }))}
                        extClasses='vkyc-remarks-action-btn'
                    />
                    <Button
                        secondary
                        label='End Call'
                        onClick={() => reviewReportAction(false)}
                        extClasses='vkyc-remarks-action-btn reject'
                        disabled={(remarkState?.rejectionReason === ''
                             || (remarkState?.rejectionReason.includes('REASON_OTHER') && remarkState?.comment === ''))}
                    />
                </div>
            </div>
        </Modal>

    );
};

export default Remarks;
