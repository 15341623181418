/**
 * @file Debit vs Credit Bar chart
 */

import React, { useContext, useEffect, useMemo, useState } from 'react';

import BarChart from '../../components/d3/BarChart';

import {
    DEBIT, SUCCESS, OTHERACTORCOUNT, BARCHART_DIMENSIONS, staticColorMapping,
} from './constants';
import { dataGrouper, setViewTimeEvent } from './utils';
import './style.scss';
import useBarXScale from '../../components/d3/useBarXScale';
import { useTransactionViewEvent } from './useTransactionViewEvent';
import { MetaInfoContext } from '../../context';

const creditVsDebitBarKey = 'accountingEntryType';
const senderVsReceiverBarKey = 'interlocutor';
const otherActorIdKey = 'otherActorId';
const creditCardCategorizationBarKey = 'display_category';

// callback to get unique actor count obejct
const distictOtherActorCount = (group) => ({
    [OTHERACTORCOUNT]: new Set(group.map((item) => item[otherActorIdKey])).size, // unique other actor count
});

const DebitCreditChart = (props) => {
    const {
        data, yValue, aggregation, onboardingDate, renderingEvent = null, fireTvPerformanceMetricEvent, metricsEvent,
        transactionViewEventState,
    } = props;

    const [modifiedData, setModifiedData] = useState([]);
    const [creditCardModifiedData, setCreditCardModifiedData] = useState([]);
    const transactionViewEvent = useTransactionViewEvent();
    const eventsMap = metricsEvent.CHARTS;
    const eventsState = transactionViewEventState.events;

    const metaInfo = useContext(MetaInfoContext);

    const {
        riskOps: {
            transactionViewV2Params: {
                creditDebitTrends: {
                    creditDebitGraph: {
                        enable: creditDebitGraphEnable,
                    },
                    creditSplitCategoryGraph: {
                        enable: creditSplitCategoryGraphEnable,
                    },
                    distinctSenderReceiverGraph: {
                        enable: distinctSenderReceiverGraphEnable,
                    },
                },
            },
        },
    } = metaInfo;

    const {
        xScale, setXScale, xScaleTemplate, zoomTransform, setZoomTransform,
    } = useBarXScale({
        aggregation,
        dimensions: BARCHART_DIMENSIONS,
        data: modifiedData,
    });

    useEffect(() => {
        if (data) {
            const creditCardCategorization = [];
            const modData = data
                // filter success transactions only
                .filter((item) => (!!item?.executed_at) // either credited_at or debited_at should be valid
                // filter success transactions only
                && (item.transaction_status && item.transaction_status.includes(SUCCESS)))
                .map((item) => {
                    if (item.accounting_entry_type === 'CREDIT') {
                        creditCardCategorization.push({
                            amount: item.amount,
                            date: item?.executed_at,
                            [otherActorIdKey]: item.other_actor_id,
                            [creditCardCategorizationBarKey]: item.display_category || 'UNCATEGORIZED',
                            [senderVsReceiverBarKey]: item.accounting_entry_type === DEBIT ? 'Receiver (To Actor)' : 'Sender (From Actor)',
                        });
                    }
                    return {
                        amount: item.amount,
                        date: item?.executed_at,
                        [otherActorIdKey]: item.other_actor_id,
                        [creditVsDebitBarKey]: item.accounting_entry_type,
                        [senderVsReceiverBarKey]: item.accounting_entry_type === DEBIT ? 'Receiver (To Actor)' : 'Sender (From Actor)',
                    };
                });
            setModifiedData(modData);
            setCreditCardModifiedData(creditCardCategorization);
        }

        if (renderingEvent) {
            window.requestAnimationFrame((event) => {
                renderingEvent(event);
            });
        }
    }, [data, renderingEvent]);

    const creditVsDebitData = useMemo(
        () => dataGrouper(modifiedData, aggregation, creditVsDebitBarKey),
        [aggregation, modifiedData],
    );

    const senderVsReceiverData = useMemo(
        () => dataGrouper(modifiedData, aggregation, senderVsReceiverBarKey, distictOtherActorCount),
        [aggregation, modifiedData],
    );

    const creditCardTxnByValue = useMemo(
        () => dataGrouper(creditCardModifiedData, aggregation, creditCardCategorizationBarKey),
        [aggregation, creditCardModifiedData],
    );

    const eventsCallback = (entries) => {
        entries.forEach((entry) => {
            const topPosition = entry.intersectionRect.top;
            const chartEvents = transactionViewEvent.events.CHARTS;
            let event;
            switch (entry.target.id) {
                case eventsState.CHART_CREDIT_VS_DEBIT.id:
                    event = chartEvents.CHART_CREDIT_VS_DEBIT;
                    break;
                case eventsState.CHART_CATEGORY.id:
                    event = chartEvents.CHART_CATEGORY;
                    break;
                case eventsState.CHART_SENDERS.id:
                    event = chartEvents.CHART_SENDERS;
                    break;
                default:
            }
            if (event) setViewTimeEvent(event, topPosition, transactionViewEventState, fireTvPerformanceMetricEvent);
        });
    };

    useEffect(() => {
        if (document.getElementById('credit-debit-root')) {
            const options = {
                root: null,
                rootMargin: '100px',
                threshold: 1,
            };

            const intersectionObserver = new IntersectionObserver(eventsCallback, options);

            Object.values(eventsMap).forEach((item) => {
                if (eventsState[item] && eventsState[item].id
                    && document.getElementById(eventsState[item].id)) intersectionObserver.observe(document.getElementById(eventsState[item].id));
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modifiedData]);

    return (

        <div className='trv2-dcc-c' id='credit-debit-root'>
            {
                modifiedData.length
                    ? (
                        <React.Fragment>
                            {creditDebitGraphEnable
                            && (
                                <div className='m-auto' id={eventsState.CHART_CREDIT_VS_DEBIT.id}>
                                    <BarChart
                                        enableGraphTypeDropdown={false}
                                        aggregation={aggregation}
                                        yValue={yValue}
                                        barKey={creditVsDebitBarKey}
                                        dimensions={BARCHART_DIMENSIONS}
                                        data={creditVsDebitData}
                                        legendPlacement='bottom'
                                        headingPlacement='top'
                                        heading='Credit vs Debit'
                                        xScale={xScale}
                                        setXScale={setXScale}
                                        xScaleTemplate={xScaleTemplate}
                                        zoomTransform={zoomTransform}
                                        setZoomTransform={setZoomTransform}
                                        onboardingDate={onboardingDate}
                                        graphTypeValue='GROUPED'
                                        staticColorMapping={staticColorMapping}
                                    />
                                </div>
                            )}

                            {creditSplitCategoryGraphEnable
                            && (
                                <div className='m-auto' id={eventsState.CHART_CATEGORY.id}>
                                    <BarChart
                                        enableGraphTypeDropdown={false}
                                        aggregation={aggregation}
                                        yValue={yValue}
                                        barKey={creditCardCategorizationBarKey}
                                        dimensions={BARCHART_DIMENSIONS}
                                        data={creditCardTxnByValue}
                                        legendPlacement='bottom'
                                        headingPlacement='top'
                                        heading='Credits split by Category'
                                        xScale={xScale}
                                        setXScale={setXScale}
                                        xScaleTemplate={xScaleTemplate}
                                        zoomTransform={zoomTransform}
                                        setZoomTransform={setZoomTransform}
                                        onboardingDate={onboardingDate}
                                        graphTypeValue='GROUPED'
                                        staticColorMapping={staticColorMapping}
                                    />
                                </div>
                            )}

                            {distinctSenderReceiverGraphEnable
                            && (
                                <div className='m-auto' id={eventsState.CHART_SENDERS.id}>
                                    <BarChart
                                        aggregation={aggregation}
                                        yValue={OTHERACTORCOUNT}
                                        barKey={senderVsReceiverBarKey}
                                        dimensions={BARCHART_DIMENSIONS}
                                        data={senderVsReceiverData}
                                        legendPlacement='bottom'
                                        headingPlacement='top'
                                        heading='Distinct Sender (From Actor) vs Receiver (To Actor)'
                                        tooTipPrecession={0} // y value tooltip precesion to 0 (zero)
                                        xScale={xScale}
                                        setXScale={setXScale}
                                        xScaleTemplate={xScaleTemplate}
                                        zoomTransform={zoomTransform}
                                        setZoomTransform={setZoomTransform}
                                        onboardingDate={onboardingDate}
                                        graphTypeValue='GROUPED'
                                        staticColorMapping={staticColorMapping}
                                    />
                                </div>
                            )}

                        </React.Fragment>
                    )
                    : null
            }
        </div>
    );
};
export default React.memo(DebitCreditChart);
