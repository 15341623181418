export const ERROR_TYPES = {
    toast: 'ERROR_VIEW_TYPE_TOAST',
    modal: 'ERROR_VIEW_TYPE_MODAL',
};

export const BUTTON_DISPLAY_THEME = {
    DISPLAY_THEME_PRIMARY: 'primary',
    DISPLAY_THEME_SECONDARY: 'secondary',
    DISPLAY_THEME_TERTIARY: 'tertiary',
};
