import React, { memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Table from '../../../components/Table';
import { ExpandableCard } from '../../../components';
import { makeSelectDisputeTableData } from '../selectors';

const ViewDisputesTable = (props) => {
    const { isDisputeTableExpanded, toggleHandler, ticketId } = props;

    const disputeTableData = useSelector(makeSelectDisputeTableData, shallowEqual);
    const { data } = disputeTableData?.[ticketId] || {};
    return (
        <ExpandableCard
            heading='All Disuptes'
            extClasses='txns-exp-card'
            isExpanded={isDisputeTableExpanded}
            setIsExpanded={toggleHandler('view-dispute-table')}
        >
            <div className='txns-dispute-table-container'>
                <Table
                    labelData={data?.columnData}
                    contentData={data?.rowData}
                    enableScrollView
                    rowDataVersion={2}
                    extClasses={{
                        contentCr: 'txns-modal-ccr-tccr',
                    }}
                />
            </div>
        </ExpandableCard>

    );
};

export default memo(ViewDisputesTable);
