/**
 * @file Right Arrow Component
 */
import React from 'react';

import { ICONS_URL } from '../../../../constants/assets';

import '../styles.scss';

const RightArrow = () => (
    <div className='arrow__right'>
        <div className='arrow-icon'>
            <img src={ICONS_URL.RIGHT_ARROW_WHITE} alt='right arrow' />
        </div>
    </div>
);

export default RightArrow;
