/**
 * @file VKYC contains rudder event names for VKYC Agent level
 */

const VKYCAgentViewRecordingStarted = 'VKYCAgentViewRecordingStarted';
const VKYCAgentViewRecordingOngoing = 'VKYCAgentViewRecordingOngoing';
const VKYCAgentViewRecordingStopped = 'VKYCAgentViewRecordingStopped';

export {
    VKYCAgentViewRecordingStarted,
    VKYCAgentViewRecordingOngoing,
    VKYCAgentViewRecordingStopped,
};
