import React from 'react';
import classNames from 'classnames';

import Arrow from '../../assets/images/svgs/arrow-upright.svg';

import Button from '../Button';

import './styles.scss';

const StepsTraversalModal = ({
    title, buttonText, buttonOnclick, backOnClick = () => {}, children, extClasses,
}) => {
    const containerClasses = classNames(extClasses && extClasses?.container);
    const titleContainerClasses = classNames('frcsbWrapper', extClasses && extClasses?.titleContainer);
    const titleClasses = classNames('step-title', extClasses && extClasses?.title);
    const buttonClasses = classNames(extClasses && extClasses?.button);
    const backButtonClasses = classNames('mb-15 p-0', extClasses && extClasses?.backButton);
    const stepClasses = classNames('step-traversal', extClasses && extClasses?.step);

    return (
        <div className={containerClasses}>
            <div className={titleContainerClasses}>
                <div className={titleClasses}>{title}</div>
                {
                    buttonText && buttonOnclick && (
                        <Button
                            link
                            label={buttonText}
                            onClick={buttonOnclick}
                            extClasses={buttonClasses}
                        />
                    )
                }
            </div>
            <div className={stepClasses}>
                <div className='step-cc p-30'>
                    {
                        backOnClick
                        && (
                            <Button
                                link
                                label={<img src={Arrow} className='sop-step-back-img' alt='Back Arrow' />}
                                extClasses={backButtonClasses}
                                onClick={backOnClick}
                            />
                        )
                    }
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StepsTraversalModal;
